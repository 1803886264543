import {yupResolver} from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  useMediaQuery,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {
  ApplicationView,
  DisputeEmailRecipient,
  PendingReasonsRaw,
  useDisputeEmails,
  useDisputeHistory,
  useNotification,
} from '@ozark/common';
import {Checkbox, getReasonsByDisposition, Loading, TextField} from '@ozark/common/components';
import {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {getAgentByUid} from '../../hooks/useAgents';

type Reason = {
  name: string;
  title: string;
  text: string | null;
};

interface ResendDisputeEmailsDialogProps {
  setDialogVisible: (args0: boolean) => void;
  application: ApplicationView;
}

interface FormDataModel {
  sendToAgent: boolean;
  agentEmail: string;
  sendToMerchant: boolean;
  merchantEmail: string;
}

const SendEmailsFormSchema = yup.object().shape({
  sendToAgent: yup.boolean(),
  agentEmail: yup.string().when('sendToAgent', (sendToAgent: boolean, schema: any) => {
    return sendToAgent
      ? schema.email('Invalid email format.').required('Email field is required')
      : schema;
  }),
  sendToMerchant: yup.boolean(),
  merchantEmail: yup.string().when('sendToMerchant', (sendToMerchant: boolean, schema: any) => {
    return sendToMerchant
      ? schema.email('Invalid email format.').required('Email field is required')
      : schema;
  }),
});

export const ResendDisputeEmailsDialog = ({
  setDialogVisible,
  application,
}: ResendDisputeEmailsDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const {getDisputeReasons} = useDisputeHistory();
  const {resendDisputeEmails} = useDisputeEmails({
    application,
    disputeReasons: getReasonsByDisposition(application.disposition),
    pendingReasonSettings: PendingReasonsRaw,
  });
  const showNotification = useNotification();
  const [loading, setLoading] = useState(false);
  const [noAgent, setNoAgent] = useState(false);
  const [sendToAgent, setSendToAgent] = useState(true);
  const [sendToMerchant, setSendToMerchant] = useState(true);

  const [agent, setAgent] = useState<{
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
  } | null>(null);
  const [merchant, setMerchant] = useState<{
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
  } | null>(null);

  const {
    control,
    handleSubmit,
    reset,
    register,
    setValue,
    formState: {errors},
  } = useForm<FormDataModel>({
    resolver: yupResolver(SendEmailsFormSchema),
    defaultValues: {
      sendToAgent: true,
      sendToMerchant: true,
    },
    shouldUnregister: true,
  });

  const fetchEmails = useCallback(async () => {
    if (!application.agent?.id) {
      setNoAgent(true);
      setSendToAgent(false);
      setValue('sendToAgent', false, {shouldDirty: true});
    }
    if (application.agent?.id) {
      const agentData = await getAgentByUid(application.agent!.id);
      setAgent({
        userId: agentData ? agentData.id : '',
        firstName: agentData ? agentData.firstName : '',
        lastName: agentData ? agentData.lastName : '',
        email: agentData ? agentData.email : '',
      });
    }

    setMerchant({
      userId: application.merchantUid ?? '',
      firstName: application.firstName,
      lastName: application.lastName,
      email: application.customerServiceEmail!,
    });
  }, [application]);

  useEffect(() => {
    if (!application || !application.disposition || ((agent || noAgent) && merchant)) return;

    fetchEmails().catch(err => {
      console.error(err);
      showNotification('error', `Error during loading agent and merchant emails`);
    });
  }, [application, application.disposition, getDisputeReasons]);

  const onSubmit = async (data: FormDataModel) => {
    setLoading(true);
    try {
      const recipients: DisputeEmailRecipient[] = [];
      if (sendToAgent) {
        recipients.push({
          user: 'agent',
          userId: agent?.userId ?? '',
          firstName: agent?.firstName ?? 'agent',
          lastName: agent?.lastName ?? '',
          email: data.agentEmail,
          isAgent: true,
        });
      }
      if (sendToMerchant) {
        recipients.push({
          user: 'merchant',
          userId: merchant?.userId ?? '',
          firstName: merchant?.firstName ?? 'merchant',
          lastName: merchant?.lastName ?? '',
          email: data.merchantEmail,
          isAgent: false,
        });
      }
      await resendDisputeEmails(recipients);
      showNotification(
        'success',
        `Notification email${
          recipients.length > 1 ? 's have' : ' has'
        } been successfully sent: ${recipients.map(x => x.email).join(', ')}`
      );
      reset(data as any);
    } catch (err) {
      console.error(err);
      showNotification('error', 'An error occurred while sending registration email.');
    } finally {
      setLoading(false);
      cancelDialog();
    }
  };

  const onSubmitError = (data: any) => {
    console.error(data);
    setLoading(false);
  };

  const cancelDialog = () => {
    setDialogVisible(false);
  };

  if ((!agent && !noAgent) || !merchant) return <Loading />;

  return (
    <Dialog
      open={true}
      onClose={cancelDialog}
      aria-labelledby="resend-pending-email-dialog-title"
      maxWidth={'lg'}
    >
      <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <DialogTitle id="resend-pending-email-dialog-title">Resend Pending Emails</DialogTitle>
        <DialogContentStyled>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Checkbox
                sx={{
                  paddingTop: 2,
                  marginBottom: -2,
                }}
                name="sendToAgent"
                label="Send to Agent"
                value={sendToAgent}
                checked={sendToAgent}
                disabled={noAgent}
                onClick={(event: any) => event.stopPropagation()}
                onFocus={(event: any) => event.stopPropagation()}
                onChange={() => {
                  const value = !sendToAgent;
                  setSendToAgent(value);
                  setValue('sendToAgent', value, {shouldDirty: true});
                }}
                register={register}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="agentEmail"
                label="Agent Email"
                defaultValue={!noAgent && agent?.email ? agent.email : ''}
                errors={errors}
                control={control}
                disabled={loading || noAgent}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                sx={{
                  paddingTop: 2,
                  marginBottom: -2,
                }}
                name="sendToMerchant"
                label="Send to Merchant"
                checked={sendToMerchant}
                onClick={(event: any) => event.stopPropagation()}
                onFocus={(event: any) => event.stopPropagation()}
                onChange={() => {
                  const value = !sendToMerchant;
                  setSendToMerchant(value);
                  setValue('sendToMerchant', value, {shouldDirty: true});
                }}
                register={register}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="merchantEmail"
                label="Merchant Email"
                defaultValue={merchant.email}
                errors={errors}
                control={control}
                disabled={loading}
                autoFocus
              />
            </Grid>
          </Grid>
        </DialogContentStyled>
        <DialogActions>
          <Button onClick={cancelDialog} disabled={loading}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={loading || (!sendToAgent && !sendToMerchant)}
            type="submit"
          >
            {loading && <CircularProgressStyled size={24} />}
            Send Emails
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const DialogContentStyled = styled(DialogContent)({
  width: 500,
  minHeight: 130,
});
const CircularProgressStyled = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
});
