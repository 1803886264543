import {EmailTemplate} from '.';
import {Application} from '../documents';
import {Dispositions} from './Dispositions';

type EmailRecipients = {
  to: string[];
  bcc: string[];
};

type NotificationContent = {
  subject?: string;
  title?: string;
  body: string;
};

type BaseEmailNotification = {
  to?: string[];
  bcc?: string[];
  notifyAgent?: boolean;
  notifyGroupAdmin?: boolean;
  bccNotifyMasterAgent?: boolean;
  content: NotificationContent;
  useBccForGroupAdmin?: boolean;
  isEnabled?: boolean;
  emailSettingsName?: EmailSettingNames;
};

type NotificationCondition = BaseEmailNotification & {
  condition: (application: Application) => boolean;
  sendOriginalToo?: boolean;
};

export type EmailNotification = BaseEmailNotification & {
  template: EmailTemplate;
  specialConditions?: NotificationCondition[];
};

export const dispositionsForSendingEmailToMerchant = [
  Dispositions.uwPending,
  Dispositions.uwDeclined,
  Dispositions.uwWithdrawn,
];
export const dispositionsForSendingEmailToAgent = [
  ...dispositionsForSendingEmailToMerchant,
  Dispositions.asDeclined,
  Dispositions.asWithdrawn,
];

export const getResolvedEmailRecipients = (emailRecipients: EmailRecipients) => {
  return emailRecipients;
};

export const getResolvedNotification = (
  application: Application,
  notification: EmailNotification
) => {
  const condition = notification?.specialConditions?.find(c => c.condition(application));

  if (condition) {
    if (condition.to) {
      notification.to = condition.to;
    }

    if (condition.bcc) {
      notification.bcc = condition.bcc;
    }

    if (condition.notifyAgent !== undefined) {
      notification.notifyAgent = condition.notifyAgent;
    }

    if (condition.notifyGroupAdmin !== undefined) {
      notification.notifyGroupAdmin = condition.notifyGroupAdmin;
    }

    if (condition.bccNotifyMasterAgent !== undefined) {
      notification.bccNotifyMasterAgent = condition.bccNotifyMasterAgent;
    }

    if (condition.useBccForGroupAdmin !== undefined) {
      notification.useBccForGroupAdmin = condition.useBccForGroupAdmin;
    }

    if (condition.emailSettingsName != undefined) {
      notification.emailSettingsName = condition.emailSettingsName;
    }

    notification.content = condition.content;
  }

  return {notification, sendOriginalToo: condition?.sendOriginalToo};
};

export enum EmailSettingNames {
  DispositionChangedIncomplete = 'DispositionChangedIncomplete',
  DispositionChangedAsReceived = 'DispositionChangedAsReceived',
  DispositionChangedAsReceivedSigned = 'DispositionChangedAsReceivedSigned',
  DispositionChangedAsIncomplete = 'DispositionChangedAsIncomplete',
  DispositionChangedAsDeclined = 'DispositionChangedAsDeclined',
  DispositionChangedAsWithdrawn = 'DispositionChangedAsWithdrawn',
  DispositionChangedAsSupportingDocumentsReceived = 'DispositionChangedAsSupportingDocumentsReceived',
  DispositionChangedUwReceived = 'DispositionChangedUwReceived',
  DispositionChangedUwReceivedSigned = 'DispositionChangedUwReceivedSigned',
  DispositionChangedUwSubmittedToOtherBank = 'DispositionChangedUwSubmittedToOtherBank',
  DispositionChangedUwPending = 'DispositionChangedUwPending',
  DispositionChangedUwDeclined = 'DispositionChangedUwDeclined',
  DispositionChangedUwWithdrawn = 'DispositionChangedUwWithdrawn',
  DispositionChangedFailed = 'DispositionChangedFailed',
  DispositionChangedBoarded = 'DispositionChangedBoarded',
  DispositionChangedBoardedConditionallyApproved = 'DispositionChangedBoardedConditionallyApproved',
  ContactForm = 'ContactForm',
  ACHConfirmation = 'ACHConfirmation',
  BancorpCompliance = 'BancorpCompliance',
  RiskGroup = 'RiskGroup',
  ApplicationOnChangeASNotifications = 'ApplicationOnChangeASNotifications',
  ApplicationOnChangeUWNotifications = 'ApplicationOnChangeUWNotifications',
  DisputeResponseProcessingWorkflowIssue = 'DisputeResponseProcessingWorkflowIssue',
  TicketsSummary = 'TicketsSummary',
}
