import {ApiBase, SearchCriteria, toQuery} from '@ozark/common';
import {ActiveFilter} from '@ozark/common/components';
import {
  AgentResidual,
  GroupResidual,
  ResidualOverview,
  ResidualPayout,
} from '@ozark/functions/src/functions/express/private/types/Residual';
import {PaginatedResponse} from '@ozark/functions/src/shared';

export class ResidualsApi extends ApiBase {
  getResidualExport = (yearMonth: string) => {
    return this.get<any[]>(`residuals/export?yearMonth__eq=${yearMonth}`);
  };
  getResidualPayoutByAgent = (yearMonth: string) => {
    return this.get<ResidualPayout[]>(`residuals/profits?yearMonth__eq=${yearMonth}`);
  };
  getGroupResidualOverview = () => {
    return this.get<ResidualOverview[]>(`residuals/overview`);
  };
  getGroupResiduals = (searchCriteria: SearchCriteria, filters?: ActiveFilter[]) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<PaginatedResponse<GroupResidual>>(`residuals/group?${queryString}`);
  };
  getGroupResidualsExport = (searchCriteria: SearchCriteria, filters?: ActiveFilter[]) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<GroupResidual[]>(`residuals/group/export?${queryString}`);
  };
  getAgentResiduals = (
    searchCriteria: SearchCriteria,
    filters?: ActiveFilter[],
    agentId?: string
  ) => {
    const queryString = toQuery(searchCriteria, filters);
    const agentParam = agentId ? `firestoreAgentId__eq=${agentId}` : '';
    return this.get<PaginatedResponse<AgentResidual>>(
      `residuals/agent?${agentParam}&${queryString}`
    );
  };
  getAgentResidualsExport = (
    searchCriteria: SearchCriteria,
    filters?: ActiveFilter[],
    agentId?: string
  ) => {
    const queryString = toQuery(searchCriteria, filters);
    const agentParam = agentId ? `firestoreAgentId__eq=${agentId}` : '';
    return this.get<AgentResidual[]>(`residuals/agent/export?${agentParam}&${queryString}`);
  };
}
