import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  CallableFunctionName,
  ExportToCsv,
  Firebase,
  GroupRole,
  PublishedResidualOptionsResults,
  SearchCriteria,
  useCallable,
  UserRoles,
} from '@ozark/common';
import {
  ActiveFilter,
  FilterOption,
  Filters,
  Loading,
  Square,
  Table,
  Title,
} from '@ozark/common/components';
import {PaginatedResponse} from '@ozark/functions/src/functions/express/private/types/PaginatedResponse';
import {Residuals} from '@ozark/functions/src/lib/postgres/models/Residuals';
import {groupBy} from '@s-libs/micro-dash';
import {parse} from 'date-fns';
import {format} from 'date-fns-tz';
import {isEmpty} from 'lodash';
import startcase from 'lodash/startCase';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router';
import {
  ResidualGroupAdjustments,
  ResidualGroupAdjustmentsLineItems,
} from '../../../../functions/src/functions/callable/dispatch_GetResidualGroupAdjustments';
import {useStore} from '../../store/helpers';
import {hasResidualsPermissions} from '../../utils/hasResidualsPermissions';
import {currentFormatter, forceActiveFilter, useReportingPageStyles} from '../ReportingPage';

const ResidualsFilters: FilterOption[] = [
  {
    id: 'mid',
    column: 'mid',
    label: 'MID',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dba',
    column: 'dba',
    label: 'DBA',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'yearMonth',
    column: 'yearMonth',
    label: 'Date',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
];

export const DefaultCriteria: SearchCriteria = {
  limit: 10, // page size
  offset: 1, // page
  order: 'desc',
  orderBy: 'yearMonth',
};

type DataRow = PaginatedResponse<
  Residuals & {
    groupProfitAdjusted: number | null;
    ShareSplitPercent: number | null;
    shareNetSplit: number | null;
  }
> & {
  bankcardSalesVolume: string;
  totalIncome: string;
  groupExpense: string;
  groupNet: string;
  groupNetSplit: string;
  agentExpense: string;
  agentNet: string;
  agentNetSplit: string;
  transactionCount: string;
  groupProfit: string;
  agentProfit: string;
  subAgentProfit: string;
  shareNetSplit: string;
  groupProfitAdjusted: number;
};

interface AgentPayoutsToDisplay {
  agentName: string;
  description: string;
  amount: number;
}

const ResidualsGroupPage = () => {
  const [loadingResiduals, setLoadingResiduals] = useState(true);
  const classes = useReportingPageStyles();
  const {apiClient, authProfile, claims} = useStore();
  const [loadingAgentResiduals, setLoadingAgentResiduals] = useState(true);
  const [residuals, setResiduals] = useState<DataRow | null>(null);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DefaultCriteria);
  const [filters, setFilters] = useState<{[_: string]: ActiveFilter}>({});
  const [yearMonths, setYearMonths] = useState<string[] | null>(null);
  const [selectedYearMonth, setSelectedYearMonth] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [agentPayouts, setAgentPayouts] = useState<AgentPayoutsToDisplay[] | null>(null);
  const [groupPayouts, setGroupPayouts] = useState<ResidualGroupAdjustments[] | null>(null);
  const [agentPayoutAdj, setAgentPayoutAdj] = useState(0);
  const [groupPayoutAdj, setGroupPayoutAdj] = useState(0);
  const [width, setWidth] = useState<any>(0);
  const ref = useRef<HTMLDivElement>(null);

  const {getResidualAdjustments, getResidualGroupAdjustments} = useCallable();

  // check permissions
  const history = useHistory();
  useEffect(() => {
    // same permissions as navigator config isVisible: ...
    if (
      claims?.role !== UserRoles.agent ||
      claims?.groupRole !== GroupRole.administrator ||
      !hasResidualsPermissions(authProfile?.data)
    ) {
      history.push('/');
    }
  }, [claims?.role, claims?.groupRole, authProfile?.data, history]);

  useEffect(() => {
    if (!selectedYearMonth) {
      setAgentPayouts(null);
      setGroupPayouts(null);
      return;
    }

    setLoadingAgentResiduals(true);
    getResidualAdjustments({
      yearMonth: selectedYearMonth,
      appendManualAdjustments: false,
    })
      .then(result => {
        if (result.status === 'ok') {
          setAgentPayouts(result.data);
        }
      })
      .finally(() => {
        setLoadingAgentResiduals(false);
      });

    getResidualGroupAdjustments({
      yearMonth: selectedYearMonth,
    }).then(result => {
      if (result.status === 'ok') {
        setGroupPayouts(result.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearMonths, selectedYearMonth]);

  useEffect(() => {
    if (!agentPayouts) {
      setAgentPayoutAdj(0);
      return;
    }

    const nextPayoutAdj = agentPayouts.reduce(
      (previousValue, current) => previousValue + current.amount,
      0
    );

    setAgentPayoutAdj(nextPayoutAdj);
  }, [agentPayouts]);

  useEffect(() => {
    if (!groupPayouts) {
      setGroupPayoutAdj(0);
      return;
    }

    const nextPayoutAdj = groupPayouts
      .map(x => x.lineItems)
      .flatMap(x => x)
      .reduce((previousValue, current) => previousValue + current.amount, 0);

    setGroupPayoutAdj(nextPayoutAdj);
  }, [groupPayouts]);

  useLayoutEffect(() => {
    if (loadingAgentResiduals) return;
    setTimeout(() => {
      if (ref?.current) {
        setWidth(ref.current.offsetWidth);
      }
    }, 200);
  }, [loadingAgentResiduals]);

  useEffect(() => {
    (async () => {
      const getPublishedResidualOptions = Firebase.functions.httpsCallable(
        CallableFunctionName.getPublishedResidualOptions
      );
      const result: {data: PublishedResidualOptionsResults} = await getPublishedResidualOptions({});

      if (!result?.data?.options) {
        setLoadingResiduals(false);
        return;
      }

      if (result?.data?.options) {
        const options = result.data.options.sort();
        setSelectedYearMonth(options[options.length - 1]);
        setYearMonths(options);
        if (!options.length) {
          setLoadingResiduals(false);
        }
        const forcedFilter = forceActiveFilter(
          ResidualsFilters,
          'yearMonth',
          '__eq',
          options[options.length - 1]
        );
        setFilters({...filters, yearMonth: forcedFilter});
      }
    })();
    // eslint-disable-next-line
  }, []);

  const getResiduals = () => {
    setLoadingResiduals(true);
    apiClient.residuals
      .getGroupResiduals(searchCriteria, Object.values(filters))
      .then((result: any) => {
        setResiduals(result || {});
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoadingResiduals(false);
      });
  };

  const exportToCsv = async () => {
    const options = {
      fieldSeparator: ',',
      filename: `residuals-${selectedYearMonth}`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Residuals',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const exportData = await apiClient.residuals.getGroupResidualsExport(
      searchCriteria,
      Object.values(filters)
    );

    if (!exportData) return;

    const exporter = new ExportToCsv(options);

    const noNullData = JSON.parse(JSON.stringify(exportData).replace(/null/gi, '""'));

    exporter.generateCsv(noNullData);
  };

  useEffect(() => {
    if (!selectedYearMonth) return;
    getResiduals();
    // eslint-disable-next-line
  }, [searchCriteria, filters]);

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const handleDateChange = (event: any) => {
    const yearMonth = event.target.value;
    setSelectedYearMonth(yearMonth);
    const forcedFilter = forceActiveFilter(ResidualsFilters, 'yearMonth', '__eq', yearMonth);
    setFilters({...filters, yearMonth: forcedFilter});
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleApplyFilter = (filter: ActiveFilter) => {
    setFilters({...filters, [filter.option.column]: filter});
  };

  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFilters(_filters);
  };

  const formatYearMonth = (yearMonth: string) => {
    const date = parse(yearMonth as string, 'yyyyMM', new Date());
    return format(date, 'MMMM, yyyy');
  };

  const getBreadcrumbs = () => {
    return [<Typography variant="body1">Group Residuals</Typography>];
  };

  if (authProfile.promised) return <Loading />;

  if (loadingResiduals) {
    return <Loading />;
  }

  if (!yearMonths || yearMonths.length === 0) {
    return (
      <Box mt={6}>
        <Typography variant="body1" align="center">
          No residuals have been added.
        </Typography>
      </Box>
    );
  }

  return (
    <div className={classes.root} ref={ref}>
      <Title breadcrumbs={getBreadcrumbs()}>
        <div className={classes.grow} />
        {yearMonths && (
          <TextField
            value={selectedYearMonth}
            onChange={handleDateChange}
            InputProps={{
              classes: {
                input: classes.selectInput,
              },
              disableUnderline: true,
            }}
            select
          >
            {yearMonths.map(e => {
              return (
                <MenuItem key={e} value={e}>
                  {formatYearMonth(e)}
                </MenuItem>
              );
            })}
          </TextField>
        )}
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <Filters options={ResidualsFilters} onApplyFilter={handleApplyFilter} />
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <Button onClick={exportToCsv}>Export</Button>
      </Title>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.menuPaper,
        }}
      ></Menu>

      {residuals && isEmpty(residuals) && (
        <Typography className={classes.noContent}>No Residuals</Typography>
      )}
      {residuals && !isEmpty(residuals) && (
        <>
          <Grid container spacing={2} direction="row" alignItems="stretch">
            <Grid item xs={12}>
              {filters && !isEmpty(filters) && (
                <IconButton disabled size="large">
                  <FilterListIcon />
                </IconButton>
              )}
              {filters &&
                Object.keys(filters).map(key => {
                  const filter = filters[key];
                  if (filter.option.type === 'dateRange' && filter.operator.id === '__between') {
                    return (
                      <Chip
                        key={`${key}-${filter.operator.id}`}
                        className={classes.chip}
                        label={
                          <span>
                            <b>{filter.option.label}</b> {filter.operator.label} '
                            <b>{format(filter.value?.[0] as Date, 'MM/dd/yyyy h:mm a')}</b>' and '
                            <b>{format(filter.value?.[1] as Date, 'MM/dd/yyyy h:mm a')}</b>'
                          </span>
                        }
                        variant="outlined"
                        onDelete={handleDeleteFilter(key)}
                      />
                    );
                  }
                  if (filter.option.type === 'date' && filter.operator.id === '__between') {
                    return (
                      <Chip
                        key={`${key}-${filter.operator.id}`}
                        className={classes.chip}
                        label={
                          <span>
                            <b>{filter.option.label}</b> {filter.operator.label} '
                            <b>{format(filter.value?.[0] as Date, 'MM/dd/yyyy')}</b>'
                          </span>
                        }
                        variant="outlined"
                        onDelete={handleDeleteFilter(key)}
                      />
                    );
                  }
                  if (filter.option.id === 'yearMonth') {
                    return (
                      <Chip
                        key={`${key}-${filter.operator.id}`}
                        className={classes.chip}
                        label={
                          <span>
                            <b>{filter.option.label}</b> {filter.operator.label} '
                            <b>{formatYearMonth(filter.value as string)}</b>'
                          </span>
                        }
                        variant="outlined"
                        onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                      />
                    );
                  }
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>
                            {filter.option.type === 'currency'
                              ? `$${filter.value}`
                              : `${filter.value}`}
                          </b>
                          '
                        </span>
                      }
                      variant="outlined"
                      onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                    />
                  );
                })}
            </Grid>

            <Grid item xs={3}>
              <Square
                lines={{
                  'Total Transactions': parseFloat(residuals?.transactionCount || '0'),
                }}
                center
              />
            </Grid>

            <Grid item xs={3}>
              <Square
                lines={{
                  'Total Sales Volume': currentFormatter.format(
                    parseFloat(residuals?.bankcardSalesVolume || '0')
                  ),
                }}
                center
              />
            </Grid>

            <Grid item xs={3}>
              <Square
                lines={{
                  'Total Agent Payout': currentFormatter.format(
                    parseFloat(residuals.agentNetSplit || '0')
                  ),
                }}
                center
              />
            </Grid>

            <Grid item xs={3}>
              <Square
                lines={{
                  'Total Group Profit': currentFormatter.format(
                    residuals.groupProfitAdjusted || Number.parseFloat(residuals.groupProfit) || 0
                  ),
                }}
                center
              />
            </Grid>
          </Grid>
          <Box
            component={Paper}
            overflow="auto"
            mt={2}
            sx={[{'& > div:first-child': {width: '100%'}}, {width: {width}}]}
          >
            <Table
              noWrap={true}
              columns={[
                {
                  id: 'yearMonth',
                  numeric: false,
                  sortable: true,
                  export: true,
                  label: 'Date',
                  selector: row => {
                    return formatYearMonth(row.yearMonth);
                  },
                },
                {
                  id: 'mid',
                  numeric: false,
                  sortable: true,
                  label: 'Merchant ID',
                },
                {
                  id: 'agentName',
                  numeric: false,
                  sortable: true,
                  label: 'Agent Name',
                },
                {
                  id: 'dba',
                  numeric: false,
                  sortable: true,
                  label: 'DBA',
                },
                {
                  id: 'riskLevel',
                  numeric: false,
                  sortable: true,
                  label: 'Risk Level',
                  selector: row => startcase(row.riskLevel),
                },
                {
                  id: 'feeModifierCount',
                  numeric: true,
                  sortable: false,
                  label: 'Fee Modifiers',
                },
                {
                  id: 'transactionCount',
                  numeric: true,
                  sortable: true,
                  label: 'Transaction Count',
                },
                {
                  id: 'bankcardSalesVolume',
                  numeric: true,
                  sortable: true,
                  label: 'Sales Volume',
                  selector: row => currentFormatter.format(row.bankcardSalesVolume),
                },
                {
                  id: 'totalIncome',
                  numeric: true,
                  sortable: true,
                  label: 'Merchant Income',
                  selector: row => currentFormatter.format(row.totalIncome),
                },
                {
                  id: 'groupExpense',
                  numeric: true,
                  sortable: true,
                  label: 'Group Expense',
                  selector: row =>
                    row.groupExpense ? currentFormatter.format(row.groupExpense) : '',
                },
                {
                  id: 'groupNet',
                  numeric: true,
                  sortable: true,
                  label: 'Gross Group Profit',
                  selector: row => (row.groupNet ? currentFormatter.format(row.groupNet) : ''),
                },
                {
                  id: 'groupSplitPercent',
                  numeric: true,
                  sortable: true,
                  label: 'Group Split',
                  selector: row => (row.groupSplitPercent ? `${row.groupSplitPercent}%` : ''),
                },
                {
                  id: 'groupNetSplit',
                  numeric: true,
                  sortable: true,
                  label: 'Group Net',
                  selector: row =>
                    row.groupNetSplit ? currentFormatter.format(row.groupNetSplit) : '',
                },
                {
                  id: 'agentExpense',
                  numeric: true,
                  sortable: true,
                  label: 'Agent Expense',
                  selector: row =>
                    row.agentExpense ? currentFormatter.format(row.agentExpense) : '',
                },
                {
                  id: 'agentNet',
                  numeric: true,
                  sortable: true,
                  label: 'Agent Net',
                  selector: row => (row.agentNet ? currentFormatter.format(row.agentNet) : ''),
                },
                {
                  id: 'agentSplitPercent',
                  numeric: true,
                  sortable: true,
                  label: 'Agent Split',
                  selector: row => (row.agentSplitPercent ? `${row.agentSplitPercent}%` : ''),
                },
                {
                  id: 'agentNetSplit',
                  numeric: true,
                  sortable: true,
                  label: 'Agent Payout',
                  selector: row =>
                    row.agentNetSplit ? currentFormatter.format(row.agentNetSplit) : '',
                },
                {
                  id: 'subAgentExpense',
                  numeric: true,
                  sortable: true,
                  label: 'Sub Agent Expense',
                  selector: row =>
                    row.subAgentExpense ? currentFormatter.format(row.subAgentExpense) : '',
                },
                {
                  id: 'subAgentNet',
                  numeric: true,
                  sortable: true,
                  label: 'Sub Agent Net',
                  selector: row =>
                    row.subAgentNet ? currentFormatter.format(row.subAgentNet) : '',
                },
                {
                  id: 'subAgentSplitPercent',
                  numeric: true,
                  sortable: true,
                  label: 'Sub Agent Split',
                  selector: row => (row.subAgentSplitPercent ? `${row.subAgentSplitPercent}%` : ''),
                },
                {
                  id: 'subAgentNetSplit',
                  numeric: true,
                  sortable: true,
                  label: 'Sub Agent Profit',
                  selector: row =>
                    row.subAgentNetSplit ? currentFormatter.format(row.subAgentNetSplit) : '',
                },
                {
                  id: 'groupProfit',
                  numeric: true,
                  sortable: true,
                  label: 'Group Profit',
                  selector: row =>
                    row.groupProfit ? currentFormatter.format(row.groupProfit) : '',
                },
                {
                  id: 'agentProfit',
                  numeric: true,
                  sortable: true,
                  label: 'Agent Profit',
                  selector: row =>
                    row.agentProfit ? currentFormatter.format(row.agentProfit) : '',
                },
                {
                  id: 'subAgentProfit',
                  numeric: true,
                  sortable: true,
                  label: 'Sub Agent Profit',
                  selector: row =>
                    row.subAgentProfit ? currentFormatter.format(row.subAgentProfit) : '',
                },
                {
                  id: 'shareCount',
                  numeric: true,
                  sortable: false,
                  label: 'Share Count',
                },
                {
                  id: 'shareSplitPercent',
                  numeric: true,
                  sortable: false,
                  label: 'Share Split',
                  selector: row => (row.shareSplitPercent ? `${row.shareSplitPercent}%` : ''),
                },
                {
                  id: 'shareNetSplit',
                  numeric: true,
                  sortable: false,
                  label: 'Share Amount',
                  selector: row =>
                    row.shareNetSplit ? currentFormatter.format(row.shareNetSplit) : '',
                },
                {
                  id: 'groupProfitAdjusted',
                  numeric: true,
                  sortable: false,
                  label: 'Adjusted Group Profit',
                  selector: row =>
                    row.groupProfitAdjusted ? currentFormatter.format(row.groupProfitAdjusted) : '',
                },
              ]}
              data={residuals}
              summary={{
                bankcardSalesVolume: currentFormatter.format(
                  parseFloat(residuals?.bankcardSalesVolume || '0')
                ),
                transactionCount: parseFloat(residuals?.transactionCount || '0'),
                totalIncome: currentFormatter.format(parseFloat(residuals?.totalIncome || '0')),
                groupExpense: currentFormatter.format(parseFloat(residuals?.groupExpense || '0')),
                groupNetSplit: currentFormatter.format(parseFloat(residuals?.groupNetSplit || '0')),
                groupNet: currentFormatter.format(parseFloat(residuals?.groupNet || '0')),
                agentExpense: currentFormatter.format(parseFloat(residuals?.agentExpense || '0')),
                agentNet: currentFormatter.format(parseFloat(residuals?.agentNet || '0')),
                agentNetSplit: currentFormatter.format(parseFloat(residuals?.agentNetSplit || '0')),
                groupProfit: currentFormatter.format(parseFloat(residuals?.groupProfit ?? '0')),
                agentProfit: currentFormatter.format(parseFloat(residuals?.agentProfit || '0')),
                subAgentProfit: currentFormatter.format(
                  parseFloat(residuals?.subAgentProfit || '0')
                ),
                shareNetSplit: currentFormatter.format(parseFloat(residuals?.shareNetSplit || '0')),
                groupProfitAdjusted: currentFormatter.format(
                  parseFloat((residuals?.groupProfitAdjusted ?? 0).toString())
                ),
              }}
              onRowClick={() => {}}
              onRetrieveData={handleRetrieveData}
              paginate
              stickyHeader
              scrollableBody
            />

            {!!groupPayouts && (
              <Box mt={2} p={2}>
                <Typography variant="h5">Group Adjustments</Typography>

                <TableContainer component={Paper}>
                  <MuiTable sx={{maxWidth: 650}}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Description</TableCell>
                        <TableCell align="right">Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(
                        groupBy(groupPayouts, groupPayout => groupPayout.group.name)
                      ).map(([groupName, payouts], index) =>
                        payouts
                          .map(x => x.lineItems)
                          .flatMap(x => x)
                          .flatMap(x => x as ResidualGroupAdjustmentsLineItems)
                          .map(({amount, description}, idx) => (
                            <TableRow
                              key={`${groupName}-${index}-${idx}`}
                              sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                              <TableCell align="right">{description}</TableCell>

                              <TableCell align="right" sx={{color: amount >= 0 ? 'green' : 'red'}}>
                                {currentFormatter.format(amount)}
                              </TableCell>
                            </TableRow>
                          ))
                      )}
                      <TableRow key="summary">
                        <TableCell align="right" sx={{fontWeight: 500}}>
                          Total
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{color: groupPayoutAdj >= 0 ? 'green' : 'red', fontWeight: 500}}
                        >
                          {currentFormatter.format(groupPayoutAdj)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </MuiTable>
                </TableContainer>
              </Box>
            )}

            {!!agentPayouts && (
              <Box mt={2} p={2}>
                <Typography variant="h5">Agent Adjustments</Typography>

                <TableContainer component={Paper}>
                  <MuiTable sx={{maxWidth: 650}}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Agent</TableCell>
                        <TableCell align="right">Description</TableCell>
                        <TableCell align="right">Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(
                        groupBy(agentPayouts, agentPayout => agentPayout.agentName)
                      ).map(([agentName, payouts], index) =>
                        payouts.map(({amount, description}, idx) => (
                          <TableRow
                            key={`${agentName}-${index}-${idx}`}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                          >
                            <TableCell component="th" scope="row">
                              {agentName}
                            </TableCell>

                            <TableCell align="right">{description}</TableCell>

                            <TableCell align="right" sx={{color: amount >= 0 ? 'green' : 'red'}}>
                              {currentFormatter.format(amount)}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                      <TableRow key="summary">
                        <TableCell align="right" colSpan={2} sx={{fontWeight: 500}}>
                          Total
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{color: agentPayoutAdj >= 0 ? 'green' : 'red', fontWeight: 500}}
                        >
                          {currentFormatter.format(agentPayoutAdj)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </MuiTable>
                </TableContainer>
              </Box>
            )}
          </Box>
        </>
      )}
    </div>
  );
};

export default ResidualsGroupPage;
