import {DashboardTable, DashTableRow} from '../common/DashboardTable';
import {DataIsLoading} from '../common/DataIsLoading';
import {DataIsMissing} from '../common/DataIsMissing';
import {WidgetProps} from '../common/types';

import {DataFormat} from '../common/utils';

export function WidgetSales({loading, data}: WidgetProps) {
  if (loading) return <DataIsLoading />;
  if (!data) return <DataIsMissing />;

  const rows: DashTableRow[] = [
    {
      label: 'Gross Volume',
      value: data.totalSales,
      dataFormat: DataFormat.currency,
    },
    {
      label: 'Refund Volume',
      value: data.totalRefunds,
      dataFormat: DataFormat.currency,
    },
    {
      label: 'Net Volume',
      value: data.netSales,
      dataFormat: DataFormat.currency,
    },
    {
      label: 'Total Transactions',
      value: data.totalTransactions,
      dataFormat: DataFormat.number,
    },
    {
      label: 'Total Refund Transactions',
      value: data.totalRefundCount,
      dataFormat: DataFormat.none,
    },
    {
      label: 'Refund Ratio %',
      value: data.refund,
      dataFormat: DataFormat.percent,
    },
    {
      label: 'Total Reserve Balance',
      value: (data.totalMerchantReserve ?? 0) - (data.totalMerchantRelease ?? 0),
      dataFormat: DataFormat.currency,
    },
  ];

  return <DashboardTable data={rows} />;
}
