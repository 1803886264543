export enum Collections {
  agents = 'agents',
  applications = 'applications',
  applicationsTemplates = 'applicationsTemplates',
  applicationsAssociations = 'applicationsAssociations',
  leads = 'leads',
  attachments = 'attachments',
  audits = 'audits',
  boardingTasks = 'boardingTasks',
  disputeHistory = 'disputeHistory',
  dictionaries = 'dictionaries',
  generateTasks = 'generateTasks',
  emails = 'emails',
  equipment = 'equipment',
  firstDataFeeClasses = 'firstDataFeeClasses',
  downloadFilesRequests = 'downloadFilesRequests',
  groups = 'groups',
  meta = 'meta',
  merchants = 'merchants',
  comments = 'comments',
  commentsInternal = 'commentsInternal',
  profiles = 'profiles',
  programs = 'programs',
  precalculations = 'precalculations',
  replies = 'replies',
  resources = 'resources',
  resourceDocumentCategories = 'resourceDocumentCategories',
  rotations = 'rotations',
  sequence = 'sequence',
  settings = 'settings',
  transferTokens = 'transferTokens',
  tickets = 'tickets',
  ticketTypes = 'ticketTypes',
  ticketTypeCategories = 'ticketTypeCategories',
  calendar = 'calendar',
  residuals = 'residuals',
  residualManualAdjustments = 'residualManualAdjustments',
  scheduleA = 'scheduleA',
  slaBreachedLogs = 'slaBreachedLogs',
  // A denormalized, synced via triggers table that can answer the following:
  // 1. What are all MIDs available (all Ozark users have access)
  // 2. What MIDs are available for specific group
  mids = 'mids',
  // A denormalized, synced via triggers table that can answer the following:
  //  1. What MIDs does a Master Agent have access to
  //  2. What MIDs does an Agent have access to.
  //  3. What MIDs does a Merchant have access to.
  midUser = 'midUser',
  /**
   * A unified users collection for notifications
   * a doc key is a uid of either a erp user or an agent
   */
  userNotifications = 'userNotifications',
  /**
   * A unified users collection for notifications
   * a doc key is a uid of either a erp user or an agent
   */
  notifications = 'notifications',
  announcements = 'announcements',
  /**
   * Monthly merchant statement records that point to PDF files in storage.
   */
  statements = 'statements',

  /**
   * Dispute records that point to dispute case. The record contains supplemental image PDF files and response.
   */
  disputes = 'disputes',
  responses = 'responses',

  watchers = 'watchers',
  workflowConfigs = 'workflowConfigs',
  workflowRuns = 'workflowRuns',
  webhooks = 'webhooks',

  subscriptionConfigs = 'subscriptionConfigs',

  keapApplication = 'keapApplication',
  salesCampaigns = 'salesCampaigns',

  businessCategories = 'businessCategories',
  simpleBusinessTypes = 'simpleBusinessTypes',
}

export enum BusinessCategorySubCollections {
  businessTypes = 'businessTypes',
}

export enum TicketSubCollections {
  history = 'history',
}

export enum ApplicationSubCollection {
  attachments = 'attachments',
  secrets = 'secrets',
  feeModifiers = 'feeModifiers',
  merchantInfo = 'merchantInfo',
  instantIdQa = 'instantIdQa',
  scorecards = 'scorecards',
  finicity = 'finicity',
  uwRiskNotes = 'notes',
  supportNotes = 'supportNotes',
  duplicates = 'duplicates',
}

export enum LeadSubCollection {
  notes = 'notes',
}

export enum AgentSubCollection {
  notes = 'notes',
}

export enum BoardingSubCollection {
  postBoardingTasks = 'postBoardingTasks',
}

export const FinicityDocumentId = {
  customer: 'customer',
  connect: 'connect',
  link: 'link',
} as const;

export const FinicityConnectSubCollection = {
  webhooks: 'webhooks',
} as const;

export const finicityDocumentId = 'finicity';
export const plaidDocumentId = 'plaid';
export const acqDocumentId = 'acq';
export const pricingAndTierDocumentId = 'pricingAndTier'; // TSYS merchant pricing data
export const versionDocumentId = 'version';
export const disputeArchiveId = 'disputeArchive';
