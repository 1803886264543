import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GroupIcon from '@mui/icons-material/Group';
import MapIcon from '@mui/icons-material/Map';
import TodayIcon from '@mui/icons-material/Today';
import {Avatar, Box, Button, Chip, Divider, Paper, Tooltip, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {styled} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  AccountStatus,
  ApplicationView,
  Dispositions,
  getColor,
  GroupView,
  mapDispositionToMerchantApplicationStatus,
  Platforms,
  TransferExpiresInDays,
  TransferStatus,
  useApplicationResume,
  UserRoles,
  Workflows,
} from '@ozark/common';
import {SearchableProfileView} from '@ozark/functions/src/documents';
import {ApplicationResponse} from '@ozark/functions/src/functions/express/private/types';
import clsx from 'clsx';
import {differenceInDays, format, formatDistanceToNow} from 'date-fns';
import {asUniversalTimestamp} from '../../helpers';
import {useUserInfo} from '../../hooks';
import {AsyncState} from '../../models';
import {Loading} from '../Loading';
import {ActionDate} from './ActionDate';
import {BusinessAddressEntry, CardDataEntry} from './CardDataEntry';
import {GroupInfo} from './Elements/GroupInfo';
import {getApplicationDate} from './helpers';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    paper: {
      minHeight: 'calc(100% - 14px)',
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      color: '#4d6575',
      padding: theme.spacing(2, 2, 0, 0),
      margin: theme.spacing(0, 0, 2, 0),
      borderLeft: `solid 8px ${theme.palette.primary.light}`,
      width: '100%',
      transition: theme.transitions.create(['padding', 'border-color', 'box-shadow']),
      duration: theme.transitions.duration.complex,
    },
    inViewport: {
      borderLeft: `solid 8px ${theme.palette.primary.light}`,
    },
    icon: {
      height: '100%',
      padding: 0,
      margin: theme.spacing(0, 1),
      '& > *': {
        fill: '#4d6575',
      },
    },
    avatar: {
      height: '100%',
      flexBasis: '10%',
      padding: theme.spacing(0, 2),
      minWidth: 120,
    },
    disposition: {
      width: 120,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    transferredViewed: {
      width: 120,
      fontWeight: 400,
      color: 'green',
      letterSpacing: '0.5px',
    },
    transferredSent: {
      width: 120,
      fontWeight: 400,
      color: 'red',
      letterSpacing: '0.5px',
    },
    transferredDate: {
      fontSize: '10px',
      letterSpacing: '0.5px',
    },
    column: {
      height: '100%',
      padding: theme.spacing(0, 3, 0, 0),
      flexBasis: '18%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transition: theme.transitions.create(['flexBasis'], {
        duration: theme.transitions.duration.standard,
      }),
      [theme.breakpoints.down('lg')]: {
        '&:hover': {
          flexBasis: '40%',
        },
      },
    },
    single: {
      height: '100%',
      flexBasis: '10%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    divider: {
      padding: 0,
    },
    grow: {
      height: '100%',
      flexGrow: 1,
    },
    speedDial: {
      position: 'absolute',
      top: `calc(50% - 28px)`,
      right: theme.spacing(2),
      padding: 0,
    },
    fab: {
      backgroundColor: 'transparent',
      boxShadow: 'none !important',
      color: '#b2c2cd',
      '&:hover,&:focus': {
        color: '#1c252c',
        backgroundColor: '#f5fafc',
      },
      '&:hover $icon': {
        fill: '#1c252c',
      },
    },
    actionFab: {
      color: '#b2c2cd',
      '&:hover,&:focus': {
        color: '#1c252c',
        backgroundColor: '#f5fafc',
      },
      '&:hover $icon': {
        fill: '#1c252c',
      },
    },
  })
);

export const AvatarWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const isAgentSupportDisposition = (disposition: Dispositions) =>
  Workflows[UserRoles.agentSupport].viewable.includes(disposition);
const isUnderWriterDisposition = (disposition: Dispositions) =>
  Workflows[UserRoles.underwriter].viewable.includes(disposition);

type CardProps = {
  application: ApplicationResponse | ApplicationView;
  onClick?: () => void;
  customizeTransferable?: boolean;
  isPortal?: boolean;
  profiles?: AsyncState<SearchableProfileView[]> & {
    dictionary: {
      [_: string]: SearchableProfileView;
    };
    byRole: {
      [_: string]: SearchableProfileView[];
    };
  };
  isPortalMerchant?: Boolean;
  group?: GroupView; // group is needed to display group thumbnail
};

export const Card = ({
  application,
  onClick,
  customizeTransferable,
  isPortal,
  profiles,
  isPortalMerchant,
  group,
}: CardProps) => {
  const classes = useStyles();

  const {isAgent, isMerchant} = useUserInfo();
  const {resumeApplication} = useApplicationResume();

  const profile = application.uid ? profiles?.dictionary[application.uid] : null;

  const getOpacity = (deleted?: boolean | null) => {
    return deleted === true ? 0.4 : 1;
  };

  const transferDate = application.transferStatus?.transferDate
    ? asUniversalTimestamp(application.transferStatus?.transferDate)!.toDate()
    : undefined;
  const recipientEmail = application.transferStatus?.recipientEmail;
  const viewedDate = application.transferStatus?.viewedDate
    ? asUniversalTimestamp(application.transferStatus?.viewedDate)!.toDate()
    : undefined;
  const linkDurationInDays = transferDate ? differenceInDays(new Date(), transferDate) : 0;
  const linkExpired = linkDurationInDays > TransferExpiresInDays;
  const appIsSent = application.transferStatus?.transferStatus === TransferStatus.applicationSent;
  const appIsViewed =
    application.transferStatus?.transferStatus === TransferStatus.applicationViewed;
  const distanceToNow = transferDate ? formatDistanceToNow(transferDate) : '';
  const transferDateAgoStr =
    distanceToNow === 'less than a minute' ? distanceToNow : `${distanceToNow} ago`;

  const getTransferredApplication = application.transferStatus && transferDate && (
    <div className={classes.avatar}>
      <AvatarWrapper>
        <Typography>Transferred</Typography>
        {recipientEmail && <Typography variant="caption">{`to ${recipientEmail}`}</Typography>}
        <Tooltip title={format(transferDate, 'MM/dd/yyyy h:mm a')}>
          <Typography variant="caption">{transferDateAgoStr}</Typography>
        </Tooltip>
        {appIsSent ||
          (appIsViewed && (
            <>
              <Typography
                variant="caption"
                align="center"
                noWrap
                className={classes.transferredSent}
              >
                {TransferStatus.applicationSent}
              </Typography>
              <Typography
                variant="caption"
                align="center"
                noWrap
                className={classes.transferredDate}
              >
                {format(transferDate, 'MM/dd/yyyy hh:mm a')}
              </Typography>
            </>
          ))}
        {appIsViewed && (
          <>
            <br />
            <Typography
              variant="caption"
              align="center"
              noWrap
              className={classes.transferredViewed}
            >
              {TransferStatus.applicationViewed}
            </Typography>
            {viewedDate && (
              <Typography
                variant="caption"
                align="center"
                noWrap
                className={classes.transferredDate}
              >
                {format(viewedDate, 'MM/dd/yyyy hh:mm a')}
              </Typography>
            )}
          </>
        )}
        {(appIsSent || appIsViewed) && linkExpired && (
          <Typography variant="caption" align="center" noWrap className={classes.transferredSent}>
            Link Expired
          </Typography>
        )}
      </AvatarWrapper>
    </div>
  );

  const disposition = application.disposition || Dispositions.incomplete;

  const isFailedPortalDisposition =
    isPortal && isAgent && application.disposition === Dispositions.failed;

  const dispositionStyle = isFailedPortalDisposition ? Dispositions.uwApproved : disposition;
  const dispositionCardTitle = isFailedPortalDisposition ? 'Boarding' : disposition;

  const applicationColor = getColor(
    dispositionStyle,
    application.accountStatus?.status === AccountStatus.closed,
    application.uwUploadedAt
  );

  const getNonTransferredApplication = () => {
    const isBoarded = application.disposition === Dispositions.boarded || isFailedPortalDisposition;
    return (
      <div className={classes.avatar}>
        <AvatarWrapper>
          {!isPortal && (
            <Tooltip title={profile ? `${profile.firstName} ${profile.lastName}` : 'Unassigned'}>
              {profile ? <Avatar src={profile.photoUrl}></Avatar> : <Avatar>?</Avatar>}
            </Tooltip>
          )}
          {application.isClosed || isPortalMerchant || isBoarded ? (
            <>
              <Typography
                variant="caption"
                align="center"
                noWrap
                className={classes.disposition}
                sx={{mt: 1}}
              >
                {application.isClosed
                  ? 'Closed'
                  : isPortalMerchant
                  ? mapDispositionToMerchantApplicationStatus[application.disposition]
                  : dispositionCardTitle}

                {isPortal &&
                  isPortalMerchant &&
                  application.disposition === Dispositions.incomplete && (
                    <Box sx={{textAlign: 'center'}}>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={e => resumeApplication(application.id, application.group.id)}
                      >
                        Resume
                      </Button>
                    </Box>
                  )}
              </Typography>
              {isAgent && application.boardedAt && (
                <ActionDate
                  date={asUniversalTimestamp(application.boardedAt)!.toDate()}
                  caption={'Boarded Date'}
                />
              )}
            </>
          ) : (
            <Tooltip title={application.disposition}>
              <Chip
                size="small"
                label={application.disposition}
                variant="outlined"
                sx={[
                  {fontWeight: '500', mt: 1},
                  {
                    color: applicationColor,
                    borderColor: applicationColor,
                  },
                ]}
              />
            </Tooltip>
          )}
        </AvatarWrapper>

        {!isPortal &&
          application.dispositionMovedToAsAt &&
          isAgentSupportDisposition(application.disposition) && (
            <ActionDate
              caption={'Received Date'}
              date={asUniversalTimestamp(application.dispositionMovedToAsAt)!.toDate()}
            />
          )}

        {application.disposition === Dispositions.uwPending && application.uwUploadedAt && (
          <ActionDate
            caption={'Pending Info Received'}
            date={asUniversalTimestamp(application.uwUploadedAt)!.toDate()}
          />
        )}

        {application.dispositionMovedToUwAt &&
          isUnderWriterDisposition(application.disposition) && (
            <ActionDate
              caption={'UW Received Date'}
              date={asUniversalTimestamp(application.dispositionMovedToUwAt)!.toDate()}
            />
          )}

        {!isPortal && application.boardedAt && isBoarded && (
          <ActionDate
            date={asUniversalTimestamp(application.boardedAt)!.toDate()}
            caption={'Boarded Date'}
          />
        )}
      </div>
    );
  };

  const {dateString: applicationDateString, label: applicationDateLabel} =
    getApplicationDate(application);

  if (profiles?.promised) return <Loading />;

  return (
    <Paper
      className={classes.paper}
      sx={{
        cursor: isPortalMerchant ? 'default' : 'pointer',
        '&:hover': isMerchant
          ? undefined
          : {
              backgroundColor: '#f7f7f7',
            },
      }}
      style={{
        borderLeftColor: applicationColor,
        opacity: getOpacity(application.deleted),
      }}
      onClick={onClick}
    >
      {application.disposition === Dispositions.incomplete &&
      application.transferStatus &&
      customizeTransferable
        ? getTransferredApplication
        : getNonTransferredApplication()}

      <Divider className={classes.divider} orientation="vertical" flexItem />

      {(!isPortal || isAgent) && (
        <>
          <div className={classes.icon}>
            <GroupIcon color="inherit" />
          </div>

          <div className={classes.column}>
            <Typography variant="subtitle2" component="p" noWrap gutterBottom>
              {isAgent ? 'Agent' : 'Group / Agent'}
            </Typography>

            {!isAgent && <GroupInfo application={application} group={group} />}

            <CardDataEntry caption="Agent">
              {application.agent ? (
                <>
                  {application.agent?.firstName ?? ''} {application.agent?.lastName ?? ''}
                </>
              ) : (
                'Unassigned'
              )}
            </CardDataEntry>
          </div>

          <Divider className={classes.divider} orientation="vertical" flexItem />
        </>
      )}

      <div className={classes.icon}>
        <TodayIcon color="inherit" />
      </div>

      <div className={classes.column}>
        <Typography variant="subtitle2" component="p" noWrap gutterBottom>
          Application Info
        </Typography>

        <CardDataEntry caption={applicationDateLabel}>{applicationDateString}</CardDataEntry>
        <CardDataEntry caption={application.mid ? 'MID' : 'Created'}>
          {application.mid ??
            formatDistanceToNow(asUniversalTimestamp(application.createdAt)!.toDate())}
        </CardDataEntry>

        <CardDataEntry caption="Application ID">{application.distinguishableId}</CardDataEntry>
      </div>

      <Divider className={classes.divider} orientation="vertical" flexItem />

      <div className={clsx(classes.icon)}>
        <MapIcon color="inherit" />
      </div>

      <div className={classes.column}>
        <Typography variant="subtitle2" component="p" noWrap gutterBottom>
          Business Info
        </Typography>

        <CardDataEntry caption="DBA">{application.doingBusinessAs}</CardDataEntry>
        <CardDataEntry caption="Legal Business Name">{application.legalBusinessName}</CardDataEntry>
        <CardDataEntry caption="Business Address">
          {application.businessAddress1 ? <BusinessAddressEntry application={application} /> : null}
        </CardDataEntry>
      </div>

      <Divider className={classes.divider} orientation="vertical" flexItem />

      <div className={clsx(classes.icon)}>
        <ContactPhoneIcon color="inherit" />
      </div>

      <div className={classes.column}>
        <Typography variant="subtitle2" component="p" noWrap gutterBottom>
          Signer Info
        </Typography>

        <CardDataEntry caption="Signer Name">
          {application.firstName && `${application.firstName} ${application.lastName}`}
        </CardDataEntry>

        <CardDataEntry caption="Business Phone">
          {application.businessPhone && (
            <a href={`tel:${application.businessPhone}`}>{application.businessPhone}</a>
          )}
        </CardDataEntry>

        <CardDataEntry caption="Customer Service Email">
          {application.customerServiceEmail && (
            <a href={`mailto:${application.customerServiceEmail}`}>
              {application.customerServiceEmail}
            </a>
          )}
        </CardDataEntry>
      </div>

      {!isAgent && (
        <>
          <Divider className={classes.divider} orientation="vertical" flexItem />

          <div className={classes.single}>
            <CreditCardIcon />
            <Typography variant="caption" noWrap>
              {application.platform || Platforms.evolve5846}
            </Typography>
          </div>
        </>
      )}
    </Paper>
  );
};
