import {Box, Button, Typography} from '@mui/material';
import {Agent, AgentView, ViewBase} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {AutoCompleteTextField, Loading, Table, Title} from '@ozark/common/components';
import {format} from 'date-fns';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useStore} from '../../store/helpers';
import {CreateDialog} from './CreateDialog';

export const columns: Column<ViewBase<Agent>>[] = [
  {
    id: 'firstName',
    label: 'First Name',
    width: 160,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    width: 160,
  },
  {
    id: 'email',
    label: 'Email',
    width: 200,
  },
  {
    id: 'phone',
    label: 'Phone',
  },
  {
    id: 'role',
    label: 'Role',
  },
  {
    id: 'lastLoginAt',
    label: 'Last Login',
    selector: row => (row.lastLoginAt ? format(row.lastLoginAt.toDate(), 'MM/dd/yyyy h:mm a') : ''),
  },
];

export const Agents = () => {
  const history = useHistory();
  const {getAuthorizedAgents, authProfile, group, isUserAdmin} = useStore();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [authorizedAgents, setAuthorizedAgents] = useState<AgentView[]>();

  const [selectedAgent, setSelectedAgent] = useState<AgentView>();
  const [filteredAgents, setFilteredAgents] = useState<AgentView[]>([]);
  const [loadAgents, setLoadAgents] = useState(true);

  const loadAuthorizedAgents = useCallback(async () => {
    const agents = await getAuthorizedAgents();
    setLoadAgents(false);
    setAuthorizedAgents(agents?.filter(agent => agent.isActive && !agent.deleted));
  }, [getAuthorizedAgents]);

  useEffect(() => {
    if (!loadAgents) {
      return;
    }
    loadAuthorizedAgents();
  }, [loadAgents, loadAuthorizedAgents]);

  useEffect(() => {
    loadAuthorizedAgents();
  }, [
    loadAuthorizedAgents,
    // Add subAgentUids as dependency for the case when current member agent creates sub-agent.
    // Once subAgentUids for the current agent is changed - refresh list
    authProfile?.data?.subAgentUids,
  ]);

  const handleRowClick = (row: AgentView) => {
    history.push(`/agents/${row.id}`);
  };

  const handleAgentCreated = (uid: string) => {
    setCreateDialogOpen(false);
    history.push(`/agents/${uid}?tab=1`);
  };

  useEffect(() => {
    if (authorizedAgents?.length && selectedAgent) {
      setFilteredAgents([selectedAgent]);
    } else {
      setFilteredAgents(authorizedAgents ?? []);
    }
  }, [authorizedAgents, selectedAgent]);

  if (!authorizedAgents?.length) return <Loading />;

  let enableAgentProfileCreation: boolean = true;
  if (!isUserAdmin() && group.data?.applicationSettings?.enableAgentProfileCreation === false) {
    // Group admin can always create new agents
    // Group Member can't create new agents if enableAgentProfileCreation is set explicitly to false.
    // If enableAgentProfileCreation is undefined - group member can still create new agents.
    enableAgentProfileCreation = false;
  }

  return (
    <>
      <Box height="100%" display="flex" flexDirection="column">
        <Title breadcrumbs={[<Typography>Agents</Typography>]} />
        <Box display="flex" justifyContent="space-between" pb={1}>
          <Box>
            {enableAgentProfileCreation && (
              <Button onClick={() => setCreateDialogOpen(true)} variant="outlined">
                Create New Agent
              </Button>
            )}
          </Box>
          <Box>
            <AutoCompleteTextField
              placeholder="Filter by Name"
              options={authorizedAgents}
              selected={selectedAgent}
              setSelected={setSelectedAgent}
              getOptionLabel={(agent: AgentView) => {
                return `${agent.firstName ?? ''} ${agent.lastName ?? ''}`.trim();
              }}
              onItemSelect={(agent: AgentView | null) => setSelectedAgent(agent ?? undefined)}
            />
          </Box>
        </Box>
        <Box position="relative" flex={1}>
          {authorizedAgents && (
            <Box
              position="absolute"
              width="100%"
              height="100%"
              overflow="auto"
              borderTop={1}
              borderColor="rgba(224, 224, 224, 1)"
            >
              <Table
                rows={filteredAgents}
                columns={columns}
                onRowClick={(row: AgentView) => handleRowClick(row)}
                stickyHeader
                flat
              />
            </Box>
          )}
        </Box>
      </Box>
      {createDialogOpen && (
        <CreateDialog
          onCancel={() => setCreateDialogOpen(false)}
          onAgentCreated={uid => handleAgentCreated(uid)}
        />
      )}
    </>
  );
};

export default Agents;
