import {styled} from '@mui/material/styles';
import {ChangeEvent, ReactNode} from 'react';

const Input = styled('input')({
  display: 'none',
});

type Props = {
  uploadKey: string;
  onUpload: (file: File) => void;
  children?: ReactNode;
  disabled?: boolean;
};

export function ButtonUpload({onUpload, uploadKey, children, disabled}: Props) {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !file.size) return;
    onUpload(file);
  };

  return (
    <label htmlFor={uploadKey}>
      <Input accept="*" id={uploadKey} type="file" onChange={handleOnChange} disabled={disabled} />
      {children}
      {/* <IconButton aria-label="add attachment" component="span" size={size}>
        <AttachFileIcon />
      </IconButton> */}
    </label>
  );
}
