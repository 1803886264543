import {TransferStatus} from '.';
import {Application} from '../documents';
import {Dispositions} from './Dispositions';
import {EmailNotification, EmailSettingNames} from './EmailNotificationConstants';
import {MailTemplates} from './EmailTemplates';

const defaultTitle =
  '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - {{after.disposition}}';

export const DispositionChangeNotification = async (
  disposition: Dispositions
): Promise<EmailNotification | null> => {
  switch (disposition) {
    case Dispositions.started:
    case Dispositions.signatureNeeded:
    case Dispositions.asRequestedSupportingDocuments:
    case Dispositions.uwApproved:
    case Dispositions.uwArchived:
    case Dispositions.uwSubmittedToOtherBank:
    case Dispositions.enqueued:
      return null;
    case Dispositions.incomplete: {
      return {
        emailSettingsName: EmailSettingNames.DispositionChangedIncomplete,
        template: {
          name: MailTemplates.emailNotification,
        },
        notifyAgent: true,
        notifyGroupAdmin: true,
        bccNotifyMasterAgent: true,
        useBccForGroupAdmin: true,
        content: {
          subject: defaultTitle,
          title: defaultTitle,
          body: 'Abandoned application',
        },
      };
    }
    case Dispositions.asReceived: {
      return {
        emailSettingsName: EmailSettingNames.DispositionChangedAsReceived,
        notifyAgent: true,
        notifyGroupAdmin: true,
        bccNotifyMasterAgent: true,
        useBccForGroupAdmin: true,
        template: {
          name: MailTemplates.emailNotification,
        },
        content: {
          subject:
            '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Agent Support Received',
          title:
            '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Agent Support Received',
          body: 'Hello{{#after.agent.firstName}} {{after.agent.firstName}}{{/after.agent.firstName}},<br /><br />Your application {{after.legalBusinessName}} dba {{after.doingBusinessAs}} has been received by Agent Support for review. Please expect a completed review and response within 24 hours.',
        },
        specialConditions: [
          {
            emailSettingsName: EmailSettingNames.DispositionChangedAsReceivedSigned,
            condition: (application: Application) =>
              Boolean(application.signature) &&
              Boolean(
                application.transferStatus?.transferStatus === TransferStatus.applicationViewed
              ),
            sendOriginalToo: true,
            notifyAgent: true,
            notifyGroupAdmin: true,
            bccNotifyMasterAgent: true,
            useBccForGroupAdmin: true,
            to: [],
            bcc: ['as@tangrampayments.com', 'lyoon@tangrampayments.com'],
            content: {
              subject:
                '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Signed',
              title:
                '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Signed',
              body: 'Hello{{#after.agent.firstName}} {{after.agent.firstName}}{{/after.agent.firstName}},<br /><br />The application for {{after.legalBusinessName}} dba {{after.doingBusinessAs}} has been signed and submitted to our team for processing. <br /><br />Please track the progress of this application within your portal.',
            },
          },
        ],
      };
    }
    case Dispositions.asIncomplete: {
      return {
        emailSettingsName: EmailSettingNames.DispositionChangedAsIncomplete,
        notifyAgent: true,
        notifyGroupAdmin: true,
        bccNotifyMasterAgent: true,
        useBccForGroupAdmin: true,
        template: {
          name: MailTemplates.emailNotification,
        },
        content: {
          subject:
            '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Incomplete',
          title:
            '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Incomplete',
          body: 'Hello{{#after.agent.firstName}} {{after.agent.firstName}}{{/after.agent.firstName}},<br /><br />Your application {{after.legalBusinessName}} dba {{after.doingBusinessAs}} is pending with Agent Support for the following reason(s): <br /> {{{disputeContent}}} <br />Please contact Agent Support for any additional questions.',
        },
      };
    }
    case Dispositions.asSupportingDocumentsReceived: {
      return {
        emailSettingsName: EmailSettingNames.DispositionChangedAsSupportingDocumentsReceived,
        notifyAgent: false, // custom implementation
        notifyGroupAdmin: true,
        bccNotifyMasterAgent: true,
        useBccForGroupAdmin: true,
        template: {
          name: MailTemplates.emailNotification,
        },
        content: {
          subject: defaultTitle,
          title: defaultTitle,
          body: 'Hello,<br />Supporting documents for your application {{after.legalBusinessName}} dba {{after.doingBusinessAs}} were received by Agent Support.',
        },
      };
    }
    case Dispositions.asDeclined: {
      return {
        emailSettingsName: EmailSettingNames.DispositionChangedAsDeclined,
        notifyAgent: false, // custom implementation
        notifyGroupAdmin: false,
        bccNotifyMasterAgent: false,
        useBccForGroupAdmin: false,
        template: {
          name: MailTemplates.emailNotification,
        },
        content: {
          subject:
            '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Declined',
          title:
            '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Declined',
          body: 'Hello{{#firstName}} {{firstName}}{{/firstName}},<br /><br />Your application {{after.legalBusinessName}} dba {{after.doingBusinessAs}} has been declined by Agent Support for the following reason(s): <br /> {{{disputeContent}}} Unfortunately, we are not able to board this merchant at this time. Please contact Agent Support for any additional questions.',
        },
      };
    }
    case Dispositions.asWithdrawn: {
      return {
        emailSettingsName: EmailSettingNames.DispositionChangedAsWithdrawn,
        notifyAgent: false, // custom implementation
        notifyGroupAdmin: true,
        bccNotifyMasterAgent: true,
        useBccForGroupAdmin: true,
        template: {
          name: MailTemplates.emailNotification,
        },
        content: {
          subject:
            '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Withdrawn',
          title:
            '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Withdrawn',
          body: 'Hello{{#firstName}} {{firstName}}{{/firstName}},<br /><br />Your application {{after.legalBusinessName}} dba {{after.doingBusinessAs}} has been withdrawn by Agent Support for the following reason(s):<br /> {{{disputeContent}}} <br />Please contact Agent Support for any additional questions.',
        },
      };
    }
    case Dispositions.uwReceived: {
      return {
        emailSettingsName: EmailSettingNames.DispositionChangedUwReceived,
        notifyAgent: true,
        notifyGroupAdmin: true,
        bccNotifyMasterAgent: true,
        useBccForGroupAdmin: true,
        template: {
          name: MailTemplates.emailNotification,
        },
        content: {
          subject: defaultTitle,
          title: defaultTitle,
          body: 'Hello{{#after.agent.firstName}} {{after.agent.firstName}}{{/after.agent.firstName}},<br />Your application {{after.legalBusinessName}} dba {{after.doingBusinessAs}} has been received by Underwriting for review. Please expect a response within 24 hours.',
        },
        specialConditions: [
          {
            emailSettingsName: EmailSettingNames.DispositionChangedUwReceivedSigned,
            condition: (application: Application) =>
              Boolean(application.signature) &&
              Boolean(
                application.transferStatus?.transferStatus === TransferStatus.applicationViewed
              ),
            sendOriginalToo: true,
            notifyAgent: true,
            notifyGroupAdmin: true,
            bccNotifyMasterAgent: true,
            useBccForGroupAdmin: true,
            content: {
              subject:
                '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Signed',
              title:
                '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Signed',
              body: 'Hello{{#after.agent.firstName}} {{after.agent.firstName}}{{/after.agent.firstName}},<br /><br />The application for {{after.legalBusinessName}} dba {{after.doingBusinessAs}} has been signed and submitted to our team for processing. <br /><br />Please track the progress of this application within your portal.',
            },
          },
        ],
      };
    }
    case Dispositions.uwPending: {
      return {
        emailSettingsName: EmailSettingNames.DispositionChangedUwPending,
        notifyAgent: false, // custom implementation
        notifyGroupAdmin: true,
        bccNotifyMasterAgent: true,
        useBccForGroupAdmin: true,
        template: {
          name: MailTemplates.emailNotification,
        },
        content: {
          subject: defaultTitle,
          title: defaultTitle,
          body: 'Hello,<br />Your application {{after.legalBusinessName}} dba {{after.doingBusinessAs}} is pending with Underwriting for the following reason(s):<br /> {{{disputeContent}}} <br />Please follow the instructions by clicking the button below. This application cannot be approved until the requested information has been received. <br /> {{{uploadButton}}} We look forward to providing exceptional service for your business.',
        },
      };
    }
    case Dispositions.uwDeclined: {
      return {
        emailSettingsName: EmailSettingNames.DispositionChangedUwDeclined,
        notifyAgent: false, // custom implementation
        notifyGroupAdmin: false,
        bccNotifyMasterAgent: false,
        useBccForGroupAdmin: false,
        template: {
          name: MailTemplates.emailNotification,
        },
        content: {
          subject: `{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Declined`,
          title: `{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Declined`,
          body: 'Hello{{#firstName}} {{firstName}}{{/firstName}},<br /><br />Your application {{after.legalBusinessName}} dba {{after.doingBusinessAs}} has been declined by Underwriting for the following reason(s):<br /> {{{disputeContent}}} <br /> Unfortunately, we are not able to board this merchant at this time. Please contact Agent Support for additional questions.',
        },
      };
    }
    case Dispositions.uwWithdrawn: {
      return {
        emailSettingsName: EmailSettingNames.DispositionChangedUwWithdrawn,
        notifyAgent: false, // custom implementation
        notifyGroupAdmin: true,
        bccNotifyMasterAgent: true,
        useBccForGroupAdmin: true,
        template: {
          name: MailTemplates.emailNotification,
        },
        content: {
          subject:
            '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Withdrawn',
          title:
            '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Application Withdrawn',
          body: 'Hello{{#firstName}} {{firstName}}{{/firstName}},<br /><br />Your application {{after.legalBusinessName}} dba {{after.doingBusinessAs}} has been withdrawn by Underwriting for the following reason(s):<br />      {{{disputeContent}}} <br />Please contact Agent Support for any additional questions. <br />',
        },
      };
    }
    case Dispositions.failed: {
      return {
        emailSettingsName: EmailSettingNames.DispositionChangedFailed,
        notifyAgent: false,
        notifyGroupAdmin: false,
        bccNotifyMasterAgent: false,
        template: {
          name: MailTemplates.emailNotification,
        },
        content: {
          subject: defaultTitle,
          title: defaultTitle,
          body: 'Application {{distinguishableId}} Failed Boarding',
        },
      };
    }
    case Dispositions.boarded: {
      return {
        emailSettingsName: EmailSettingNames.DispositionChangedBoarded,
        notifyAgent: true,
        notifyGroupAdmin: true,
        bccNotifyMasterAgent: true,
        useBccForGroupAdmin: true,
        template: {
          name: MailTemplates.emailNotification,
        },
        content: {
          subject: '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - UW Approved',
          title: '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - UW Approved',
          body: 'Hello{{#after.agent.firstName}} {{after.agent.firstName}}{{/after.agent.firstName}},<br />Your application {{after.legalBusinessName}} dba {{after.doingBusinessAs}} has been approved!<br /><br />Contact Name: {{after.firstName}} {{after.lastName}} <br />dba Address: {{after.businessAddress1}} {{after.businessAddress2}} {{after.businessCity}} {{after.businessState}} {{after.businessZip}}.<br />MCC Description: {{after.businessType}}<br />Monthly Volume: {{after.estimatedMonthlyCreditCardSales}}<br /><br />The merchant terminal or gateway information will be provided to you shortly.',
        },
        specialConditions: [
          {
            emailSettingsName: EmailSettingNames.DispositionChangedBoardedConditionallyApproved,
            condition: (application: Application) => Boolean(application.isConditionallyApproved),
            notifyAgent: true,
            notifyGroupAdmin: true,
            bccNotifyMasterAgent: true,
            useBccForGroupAdmin: true,
            content: {
              subject:
                '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Conditionally Approved',
              title:
                '{{{after.legalBusinessName}}} | {{{after.doingBusinessAs}}} - Conditionally Approved',
              body: 'Hello,<br /><br />Your application {{after.legalBusinessName}} dba {{after.doingBusinessAs}} has been conditionally approved! You may begin processing but there are additional items listed below to remove conditions on the account. <br /> {{{boardingNotesContent}}} <br /> Our team is preparing your merchant terminal or gateway information. Please check your email for additional updates.<br /><br />We look forward to providing exceptional service for your business.',
            },
          },
        ],
      };
    }
    default:
      return null;
  }
};
