import {ApiBase} from '@ozark/common';
import {
  UploadAttachmentRequest,
  UploadAttachmentResponse,
} from '@ozark/functions/src/functions/express/private/types';
import {UploadAttachmentAddNoteRequest} from '@ozark/functions/src/functions/express/private/types/Attachments';

export class AttachmentsApi extends ApiBase {
  upload = (file: FormData, params: UploadAttachmentRequest) => {
    return this.post<UploadAttachmentResponse>(
      `attachments/upload?token=${params.token}&document=${params.document}`,
      file
    );
  };

  addNote = (params: UploadAttachmentAddNoteRequest) =>
    this.post<boolean>(`attachments/notes?token=${params.token}`, {
      note: params.note,
      emailNote: params.emailNote,
      tag: params.tag,
    });
}
