import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Box, Button, IconButton, Stack, Tooltip} from '@mui/material';
import {
  AgentView,
  ApplicationView,
  DirectSplitMethodName,
  Dispositions,
  GroupView,
  SplitMethodType,
  useNotification,
} from '@ozark/common';
import {useCallback, useMemo, useState} from 'react';
import {useAgentsWithScheduleA, useCallable, useUserInfo} from '../../hooks';
import {ResidualIcon} from '../../icons';
import {ConfirmationDialog} from '../ConfirmationDialog';
import {GroupInfo, Line} from '../Widgets';
import {ApplicationResidualOverrideDialog} from './ApplicationResidualOverrideDialog';
import {ApplicationResidualOverrideFormModel} from './ApplicationResidualOverrideForm';

interface ResidualOverride {
  agentId: string;
  agentGroupId: string;
  agentFullName: string;
  agentGroupName: string;
  splitPercent: number;
  isAssignedAgent: boolean;
  hideVisibility: boolean;
  hideDeleteBtn: boolean;
}

interface ApplicationResidualOverrideDialogProps {
  isOpen: boolean;
  currentAdjustment?: ApplicationResidualOverrideFormModel;
  isAssignedAgent?: boolean;
  maxSplitPercent?: number;
}

interface ApplicationResidualDeleteDialogProps {
  isOpen: boolean;
  residualOverride?: ResidualOverride;
}

export const useApplicationResidualOverrideOverview = (
  application: ApplicationView,
  agents?: AgentView[],
  groups?: GroupView[]
) => {
  const {isErpUser, isErpAdmin, isAgent, isAgentAdmin, uid} = useUserInfo();
  const {agentsWithScheduleA} = useAgentsWithScheduleA(agents, groups);

  const currentAgent = useMemo((): AgentView | undefined => {
    if (!isAgent) {
      return undefined;
    }

    return agents?.find(x => x.id === uid);
  }, [agents]);

  const [applicationResidualOverrideDialogProps, setApplicationResidualOverrideDialogProps] =
    useState<ApplicationResidualOverrideDialogProps>({isOpen: false});
  const [applicationResidualOverrideConfirmation, setApplicationResidualOverrideConfirmation] =
    useState<ApplicationResidualDeleteDialogProps>({isOpen: false});
  const {adjustApplicationResidualOverride} = useCallable();
  const showNotification = useNotification();
  const allowResidualOverride = useMemo(() => {
    if (!application?.agent) {
      return false;
    }
    if (isAgent && !currentAgent?.subAgentUids?.length) {
      return false;
    }
    if (isErpUser && !isErpAdmin) {
      return false;
    }
    const isAssignedAgentHasDirectSplit = agentsWithScheduleA.find(
      x => x.id === application.agent?.id
    );
    return (
      isAssignedAgentHasDirectSplit?.latestScheduleAVersion?.splitMethod === SplitMethodType.direct
    );
  }, [agentsWithScheduleA, application?.agent, currentAgent, isAgent]);

  const residualsOverride = useMemo<ResidualOverride[]>(() => {
    const result: ResidualOverride[] = [];
    if (!agentsWithScheduleA) {
      return result;
    }

    let assignedAgentResidual: ResidualOverride | undefined;
    let residualShares = application?.residualShares ?? [];
    if (application?.splitPercentOverride && application.agent) {
      const splitPercentOverride = application.splitPercentOverride;
      residualShares.push({
        recipientAgentId: application.agent.id,
        splitPercent: splitPercentOverride.splitPercent,
        hideVisibility: false,
        recipientGroupId: application.group.id,
        createdBy: splitPercentOverride.createdBy,
        updatedAt: splitPercentOverride.updatedAt,
      });
    }
    if (isAgent) {
      residualShares = residualShares.filter(x => !x.hideVisibility);
      if (isAgentAdmin) {
        residualShares = residualShares.filter(x => x.recipientGroupId === currentAgent?.group?.id);
      } else {
        residualShares = residualShares.filter(
          x =>
            currentAgent?.subAgentUids?.includes(x.recipientAgentId) ||
            x.recipientAgentId === currentAgent?.id
        );
      }
    }

    for (const residualShare of residualShares) {
      let agent = agentsWithScheduleA.find(agent => agent.id === residualShare.recipientAgentId);
      if (!agent && agents) {
        //workaround for the sub agent member - he doesn't have access to his master, that is why we can't build full agentsWithScheduleA tree for him
        //TODO handle that correctly
        agent = agents.find(x => x.id === application.agent?.id);
      }

      if (!agent) {
        console.error(
          `Agent with uid ${residualShare.recipientAgentId} is not found in agent users list`
        );
        continue;
      }

      const isAssignedAgent = application.agent?.id === agent.id;

      const residualOverride: ResidualOverride = {
        agentGroupName: agent.group.name,
        agentFullName: `${agent.firstName} ${agent.lastName}`,
        agentGroupId: agent.group.id,
        agentId: agent.id,
        isAssignedAgent: isAssignedAgent,
        splitPercent: residualShare.splitPercent,
        hideVisibility: residualShare.hideVisibility,
        hideDeleteBtn: false,
      };
      if (isAssignedAgent) {
        assignedAgentResidual = residualOverride;
        continue;
      }

      result.push(residualOverride);
    }
    if (application.agent) {
      if (!assignedAgentResidual) {
        let agentWithScheduleA = agentsWithScheduleA.find(x => x.id === application.agent?.id);
        if (!agentWithScheduleA && agents) {
          //workaround for the sub agent member - he doesn't have access to his master, that is why we can't build full agentsWithScheduleA tree for him
          //TODO handle that correctly
          agentWithScheduleA = agents.find(x => x.id === application.agent?.id);
        }
        let splitPercent = 0;
        if (
          !!application.riskLevel &&
          !!agentWithScheduleA &&
          !!agentWithScheduleA.latestScheduleAVersion?.riskModels
        ) {
          splitPercent =
            agentWithScheduleA.latestScheduleAVersion.riskModels[application.riskLevel]
              ?.monthlyProcessingVolumePercent ?? 0;
        }
        assignedAgentResidual = {
          agentGroupName: application.group.name,
          agentFullName: `${application.agent.firstName} ${application.agent.lastName}`,
          agentGroupId: application.group.id,
          agentId: application.agent.id,
          isAssignedAgent: true,
          hideVisibility: false,
          hideDeleteBtn: true,
          splitPercent: splitPercent,
        };
      }
      result.unshift(assignedAgentResidual); //always display assigned agent first
    }
    return result;
  }, [
    agentsWithScheduleA,
    application?.residualShares,
    application?.agent,
    application?.group.name,
    application?.group.id,
    application?.riskLevel,
    application?.splitPercentOverride,
  ]);

  const getResidualColumns = useCallback(() => {
    let columns = {} as Line;

    for (let residualIndex = 0; residualIndex < residualsOverride.length; residualIndex++) {
      const residual = residualsOverride[residualIndex];
      const columnName = residual.isAssignedAgent
        ? 'Assigned Agents Residual Split'
        : `Residual Override # ${residualIndex}`;
      columns[columnName] = {
        value: (
          <>
            {<Box sx={{fontSize: '12px', fontStyle: 'italic'}}>{residual.agentGroupName}</Box>}
            <>{residual.agentFullName}</>
          </>
        ),
        secondaryAction: (
          <>
            <Tooltip title="Share %">
              <Box
                sx={{fontSize: '20px', flex: '0 0 auto!important'}}
              >{`${residual.splitPercent}%`}</Box>
            </Tooltip>
            {isErpUser && (
              <Tooltip
                title={
                  residual.hideVisibility
                    ? 'Agents will not see override'
                    : 'Assigned agent or Group Admin on the account will see override'
                }
              >
                <VisibilityIcon
                  color={residual.hideVisibility ? 'inherit' : 'primary'}
                  sx={{marginLeft: theme => theme.spacing(1)}}
                />
              </Tooltip>
            )}
            {(isErpAdmin || isAgentAdmin || (currentAgent && !!currentAgent.subAgentUids)) && (
              <>
                <Tooltip title="Edit">
                  <IconButton aria-label="edit" onClick={() => onEditOverrideClick(residual)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    sx={{visibility: residual.hideDeleteBtn ? 'hidden' : 'visible'}}
                    edge="end"
                    aria-label="delete"
                    onClick={() => onDeleteOverrideClick(residual)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        ),
      };
    }

    return [columns] as [Line];
  }, [residualsOverride]);

  const getGroupSplitPercent = useCallback(() => {
    if (!groups || !application.riskLevel) {
      return 0;
    }
    const group = groups.find(x => x.id === application.group.id);
    const riskModels = group?.latestScheduleAVersion?.riskModels;
    if (!riskModels) {
      return 0;
    }
    return riskModels[application.riskLevel]?.monthlyProcessingVolumePercent;
  }, [groups, application.riskLevel, application.group.id]);

  const getMaxSplitPercent = useCallback(() => {
    const groupSplitPercent = getGroupSplitPercent();
    if (!groupSplitPercent) {
      return 0;
    }
    const currentSum = residualsOverride.reduce((sum, current) => sum + current.splitPercent, 0);
    const result = groupSplitPercent - currentSum;
    if (result < 0) {
      return 0;
    }
    return result;
  }, [residualsOverride, getGroupSplitPercent]);

  const onAddOverrideClick = useCallback(() => {
    setApplicationResidualOverrideDialogProps({
      isOpen: true,
      maxSplitPercent: getMaxSplitPercent(),
    });
  }, [getMaxSplitPercent]);

  const onEditOverrideClick = useCallback(
    (residualOverride: ResidualOverride) => {
      if (!allowResidualOverride) {
        showNotification(
          'info',
          `Override is allowed only when assigned agent's Schedule A calculation method is "${DirectSplitMethodName}"`
        );
        return;
      }
      setApplicationResidualOverrideDialogProps({
        isOpen: true,
        currentAdjustment: {
          agentId: residualOverride.agentId,
          groupId: residualOverride.agentGroupId,
          splitPercent: residualOverride.splitPercent,
          hideVisibility: residualOverride.hideVisibility,
        },
        isAssignedAgent: residualOverride.isAssignedAgent,
        maxSplitPercent: getMaxSplitPercent() + (residualOverride.splitPercent ?? 0),
      });
    },
    [allowResidualOverride, getMaxSplitPercent, showNotification]
  );

  const onDeleteOverrideClick = useCallback(
    (residualOverride: ResidualOverride) => {
      if (residualOverride.isAssignedAgent) {
        const groupSplitPercent = getGroupSplitPercent();
        const maxSplitPercent = getMaxSplitPercent();
        const shareUnitsRequiredToDeleteAssignedAgentOverride =
          groupSplitPercent - residualOverride.splitPercent;
        if (maxSplitPercent - shareUnitsRequiredToDeleteAssignedAgentOverride < 0) {
          showNotification(
            'error',
            'You need to remove override for other agents before deleting override for assigned agents'
          );
          return;
        }
      }
      setApplicationResidualOverrideConfirmation({
        isOpen: true,
        residualOverride: residualOverride,
      });
    },
    [getGroupSplitPercent, getMaxSplitPercent, showNotification]
  );

  const onDeleteOverride = useCallback(
    async (residualOverride: ResidualOverride) => {
      try {
        const result = await adjustApplicationResidualOverride({
          applicationId: application.id,
          recipientGroupId: residualOverride.agentGroupId,
          recipientAgentId: residualOverride.agentId,
          splitPercent: residualOverride.splitPercent,
          hideVisibility: residualOverride.hideVisibility,
          deleteAdjustment: true,
        });

        if (result.status === 'ok') {
          showNotification('success', 'Adjustment successfully added.');
          return;
        }

        showNotification('error', result.message);
      } catch (err) {
        showNotification('error', (err as Error).message);
      }
    },
    [adjustApplicationResidualOverride, application.id, showNotification]
  );

  const groupInfo: GroupInfo | null = useMemo(
    () =>
      application.disposition === Dispositions.boarded
        ? {
            icon: <ResidualIcon />,
            title: (
              <Stack
                direction="row"
                spacing={0}
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'stretch',
                }}
              >
                <Box sx={{marginTop: 'auto', marginBottom: 'auto'}}>Residuals</Box>
                {allowResidualOverride && (
                  <Button variant="outlined" onClick={onAddOverrideClick}>
                    {' '}
                    Add Share
                  </Button>
                )}
              </Stack>
            ),
            columns: getResidualColumns(),
          }
        : null,
    [application.disposition, getResidualColumns]
  );

  const dialogs = useCallback(() => {
    return (
      <>
        {applicationResidualOverrideDialogProps.isOpen && (
          <ApplicationResidualOverrideDialog
            onClose={() => setApplicationResidualOverrideDialogProps({isOpen: false})}
            applicationId={application.id}
            currentAdjustment={applicationResidualOverrideDialogProps.currentAdjustment}
            isAssignedAgent={applicationResidualOverrideDialogProps.isAssignedAgent}
            groups={groups}
            agents={agentsWithScheduleA}
            maxSplitPercent={applicationResidualOverrideDialogProps.maxSplitPercent ?? 0}
            currentAgentGroupId={currentAgent?.group?.id}
          />
        )}
        <ConfirmationDialog
          title="Confirmation"
          message="Are you sure you want to delete this override?"
          onClose={() => setApplicationResidualOverrideConfirmation({isOpen: false})}
          onConfirm={
            applicationResidualOverrideConfirmation.isOpen
              ? () => onDeleteOverride(applicationResidualOverrideConfirmation.residualOverride!)
              : null
          }
        />
      </>
    );
  }, [applicationResidualOverrideDialogProps, applicationResidualOverrideConfirmation]);

  return {
    applicationResidualOverrideOverviewDialogs: dialogs,
    applicationResidualOverrideOverviewGroupInfo: groupInfo,
  };
};
