import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import FolderIcon from '@mui/icons-material/Folder';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import PublishIcon from '@mui/icons-material/Publish';
import {LoadingButton} from '@mui/lab';
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  SortDirection,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {format} from 'date-fns';
import prettyBytes from 'pretty-bytes';
import {
  ChangeEvent,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Application,
  ApplicationSubCollection,
  AttachmentableEntity,
  AttachmentMetadata,
  AttachmentView,
  Collections,
  DownloadInfo,
  downloadUrl,
  Firebase,
  getFileExtensionFromUrl,
  selectApplicationView,
  TooltipPlacement,
  UniversalSnapshot,
  useAttachments,
  useCallable,
  useNotification,
  UserRoles,
  useUserInfo,
} from '../..';
import {
  AttachedFiles,
  handleAttachmentRemove,
  handleAttachmentUpload,
  UploadDocument,
} from '../../util';
import {BoxEllipsis} from '../common';
import {ConfirmationDialog, ConfirmationDialogField} from '../ConfirmationDialog';
import {InlineTypography} from '../Inline';
import {Loading} from '../Loading';
import {AttachmentsTableHead} from './AttachmentsTableHead';
import {FileToUpload, InsertAttachmentsDialog} from './InsertAttachmentsDialog';
import {MoveAttachmentsDialog} from './MoveAttachmentsDialog';
import {FileFolder, HeadCell} from './types';

function stringComparator(a: string, b: string) {
  if (b?.toLowerCase() < a?.toLowerCase()) {
    return -1;
  }
  if (b?.toLowerCase() > a?.toLowerCase()) {
    return 1;
  }
  return 0;
}

function comparator(
  a: FileFolder,
  b: FileFolder,
  orderBy: keyof FileFolder | undefined,
  isDesc: boolean
) {
  // System folders should always be on top
  if (a.isSystemFolder && !b.isSystemFolder) {
    return isDesc ? -1 : 1;
  }
  if (!a.isSystemFolder && b.isSystemFolder) {
    return isDesc ? 1 : -1;
  }
  if (a.isSystemFolder && b.isSystemFolder) {
    return 0;
  }
  if (!orderBy) {
    return 0;
  }

  const bField = b[orderBy];
  const aField = a[orderBy];
  if (orderBy === 'name' || orderBy === 'label') {
    return stringComparator(aField as string, bField as string);
  }

  if (bField! < aField!) {
    return -1;
  }
  if (bField! > aField!) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof FileFolder>(
  order: SortDirection,
  orderBy: Key | undefined
): (a: FileFolder, b: FileFolder) => number {
  const isDesc = order === 'desc';
  return isDesc
    ? (a, b) => comparator(a, b, orderBy, isDesc)
    : (a, b) => -comparator(a, b, orderBy, isDesc);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
    },
    bar: {
      position: 'absolute',
      bottom: 4,
      left: 0,
      width: 'calc(100% - 0px)',
      backgroundColor: 'rgba(255, 255, 255, 0.88)',
      padding: theme.spacing(2, 9, 2, 2),
      color: '#000',
    },
    uploadButton: {
      width: 110,
    },
    deleteButton: {
      width: 160,
      marginRight: 20,
    },
    downloadButton: {
      width: 220,
      marginRight: 20,
    },
    newFolderButton: {
      width: 140,
      marginRight: 20,
    },
    moveButton: {
      width: 150,
      marginRight: 20,
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    title: {
      flex: '1 1 100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableNameHeader: {
      width: '20%',
      minWidth: 290,
    },
    tableFileLabelHeader: {
      width: '46%',
      textAlign: 'right',
    },
    tableSizeHeader: {
      minWidth: 80,
    },
    tableDateHeader: {
      minWidth: 128,
    },
    tableFileLabelRow: {
      maxWidth: 400,
      textAlign: 'right',
      paddingTop: 0,
      paddingBottom: 0,
    },
    itemIcon: {
      position: 'relative',
      top: '3px',
    },
    itemTypeText: {
      position: 'relative',
      top: '-3px',
      paddingLeft: '10px',
    },
    tableCell: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  })
);

interface AttachmentsRef {
  onUploadButtonClick: () => void;
}

type Props = {
  allowDelete: boolean;
  attachmentsRef: MutableRefObject<AttachmentsRef | null>;
  entityId: string;
  collection: string;
  hardDelete?: boolean;
  handleAttachClick: (event: MouseEvent<HTMLButtonElement>) => void;
  pendingDocument: UploadDocument | null;
  platform?: string;
  setPendingDocument: (any: any) => void;
  userId: string | undefined;
  documentTypeOptions?: string[];
  defaultFolderNames: string[];
  readOnly?: boolean;
};

type DialogOpen = {
  isOpen: boolean;
};

type DeleteDialogOpen = {
  isOpen: boolean;
  confirmationAction?: (() => Promise<void>) | null;
  message?: string;
};

const rootFolder = 'No Folder';

type CreateFolderForm = {
  folderName?: string;
};

const Attachments = ({
  allowDelete,
  attachmentsRef,
  entityId,
  collection,
  handleAttachClick,
  setPendingDocument,
  userId,
  documentTypeOptions,
  defaultFolderNames,
  hardDelete = false,
  readOnly = false,
}: Props) => {
  const classes = useStyles();
  const {isErpUser, isErpAdmin, role, userDisplayName, isAgent} = useUserInfo();
  const showNotification = useNotification();
  const {attachments} = useAttachments(entityId, collection, !isErpUser);
  const [currentAttachments, setCurrentAttachments] = useState<AttachedFiles>({});
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<DeleteDialogOpen>({
    isOpen: false,
  });
  const [createFolderAction, setCreateFolderAction] = useState<
    ((fields: CreateFolderForm) => Promise<void> | void) | null
  >(null);
  const [selectedFileFolders, setSelectedFileFolders] = useState<FileFolder[]>([]);
  const [folderNames, setFolderNames] = useState<string[]>([]);
  const [folderNamesToMove, setFolderNamesToMove] = useState<string[]>([]);
  const [filterFolder, setFilterFolder] = useState<FileFolder | null>(null);
  const [filesFolders, setFilesFolders] = useState<FileFolder[]>([]);
  const [filteredFilesFolders, setFilteredFilesFolders] = useState<FileFolder[]>([]);
  const [createDialogOpen, setCreateDialogOpen] = useState<DialogOpen>({isOpen: false});
  const [moveDialogOpen, setMoveDialogOpen] = useState<DialogOpen>({isOpen: false});
  const [isDownloading, setIsDownloading] = useState(false);
  const [order, setOrder] = useState<SortDirection>('asc');
  const [orderBy, setOrderBy] = useState<keyof FileFolder | undefined>(undefined);
  const filterFolderName = filterFolder?.name ?? null;
  const {createDownloadFilesRequest, moveAttachments} = useCallable();

  const canMove = !filterFolder?.isSystemFolder || isErpAdmin || role === UserRoles.underwriter;
  const canDelete = !filterFolder?.isSystemFolder || isErpAdmin || role === UserRoles.underwriter;

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedFileFolders(filteredFilesFolders);
      return;
    }
    setSelectedFileFolders([]);
  };

  const getFiles = (fileFolder: FileFolder) => {
    if (!attachments.data) {
      return [];
    }
    const result: (AttachmentView & AttachmentMetadata)[] = [];
    if (fileFolder.isFile) {
      const file = attachments.data.find(x => x.id === fileFolder.id);
      if (file) {
        result.push(file);
      }
    } else {
      const files = attachments.data.filter(x => (x.folderName ?? null) === fileFolder.name);
      result.push(...files);
    }

    return result;
  };

  const getFolderNames = useCallback(async () => {
    const snap = await Firebase.firestore.collection(collection).doc(entityId).get();
    if (!snap.exists) {
      return [];
    }
    const attachmentableEntity = snap.data() as AttachmentableEntity;
    return attachmentableEntity.attachmentsFolders ?? [];
  }, []);

  const getCloudPath = () => `${collection}/${entityId}`;

  useImperativeHandle(attachmentsRef, () => ({
    onUploadButtonClick() {
      setCreateDialogOpen({isOpen: true});
    },
  }));

  const addNewFolders = async (folderNames: string[]): Promise<void> => {
    if (folderNames.length === 0) {
      return;
    }
    const ref = await Firebase.firestore.collection(collection).doc(entityId);
    await ref.set(
      {
        attachmentsFolders: Firebase.FieldValue.arrayUnion(...folderNames),
      },
      {merge: true}
    );
  };

  const addNewFolder = async (folderName: string | undefined) => {
    if (!folderName || folderNames.includes(folderName)) {
      return;
    }
    await addNewFolders([folderName]);
    await populateFolderNameStates();
  };

  const uploadAttachment = (
    filesToUpload: FileToUpload[],
    index: number,
    resolve: (value: unknown) => void
  ) => {
    if (index > filesToUpload.length - 1) {
      resolve(true);
      setCreateDialogOpen({isOpen: false});
      return;
    }

    const fileToUpload = filesToUpload[index];

    handleAttachmentUpload({
      author: {
        uid: userId ?? '',
        name: userDisplayName ?? '',
        user: isErpUser ? 'erp' : isAgent ? 'agent' : 'merchant',
      },
      file: fileToUpload.file,
      cloudPath: getCloudPath(),
      entityId: entityId,
      collection: collection,
      pendingDocument: fileToUpload.uploadDocument ?? fileToUpload.file.name,
      setPendingDocument: setPendingDocument,
      files: currentAttachments,
      setFiles: setCurrentAttachments,
      showNotification: showNotification,
      onComplete: () => {
        uploadAttachment(filesToUpload, index + 1, resolve);
      },
      skipSuccessNotification: true,
      folderName: fileToUpload.folderName,
    });
  };

  const handleSelectedFiles = async (filesToUpload: FileToUpload[]) => {
    try {
      await new Promise(resolve => {
        uploadAttachment(filesToUpload, 0, resolve);
      });
      const newFolderNames = filesToUpload
        .filter(x => !!x.folderName && !folderNames.includes(x.folderName))
        .map(x => x.folderName as string);
      const folderNamesToAdd = Array.from(new Set(newFolderNames));
      if (!folderNamesToAdd.length) {
        showNotification('success', 'File(s) successfully uploaded.');
        return;
      }
      await addNewFolders(folderNamesToAdd);
      await populateFolderNameStates();
      showNotification('success', 'File(s) successfully uploaded.');
    } catch (err: any) {
      console.error(`failed to upload file. ${err.toString()}`);
      showNotification('error', 'Failed to upload.');
    }
  };

  const moveFileFolders = async (folderName: string, fileFolders: FileFolder[]) => {
    const folderToUse = folderName === rootFolder ? null : folderName;
    const attachmentsToMove: AttachmentView[] = [];
    for (const fileFolder of fileFolders) {
      if (!isErpAdmin && fileFolder.isSystemFolder) {
        continue;
      }
      attachmentsToMove.push(...getFiles(fileFolder));
    }

    if (attachmentsToMove.length > 0) {
      await moveAttachments({
        folderName: folderToUse,
        entityId,
        collection,
        attachmentIds: attachmentsToMove.map(x => x.id),
      });
    }
    if (folderName !== rootFolder) {
      await addNewFolder(folderName);
    }
  };

  const handleSelectedFileFoldersMove = async (folderName: string) => {
    try {
      await moveFileFolders(folderName, selectedFileFolders);

      setSelectedFileFolders([]);
      setMoveDialogOpen({isOpen: false});
      showNotification('success', 'File(s) successfully moved.');
    } catch (err: any) {
      console.error(`failed to move. ${err.toString()}`);
      showNotification('error', 'Failed to move.');
    }
  };

  const deleteAttachment = async (attachment: AttachmentView) => {
    const snapshot = await Firebase.firestore
      .collection(collection)
      .doc(entityId)
      .collection(Collections.attachments)
      .doc(attachment.id)
      .get();
    if (!snapshot.exists) {
      return;
    }

    if (hardDelete) {
      await snapshot.ref.delete();

      handleAttachmentRemove({
        cloudPath: attachment.cloudPath,
        showNotification: () => {},
      });
    } else {
      await snapshot.ref.set({deleted: true}, {merge: true});
    }
    if (attachment.noteId) {
      const noteAttachmentsSnap = await Firebase.firestore
        .collection(collection)
        .doc(entityId)
        .collection(ApplicationSubCollection.uwRiskNotes)
        .doc(attachment.noteId)
        .collection(Collections.attachments)
        .where('attachmentId', '==', attachment.id)
        .get();
      await Promise.all(noteAttachmentsSnap.docs.map(doc => doc.ref.delete()));
    }

    if (attachment.isEquipmentAttachment) {
      const appRef = Firebase.firestore.collection(collection).doc(entityId);
      const appSnap = await appRef.get();
      const app = selectApplicationView(appSnap as UniversalSnapshot<Application>);
      if (app.equipment?.attachment?.attachmentId === attachment.id) {
        app.equipment.VARSheetAttachmentUrl = Firebase.FieldValue.delete() as any;
        app.equipment.attachment = Firebase.FieldValue.delete() as any;
      }

      if (!!app.equipmentAdditional) {
        for (let addEq of app.equipmentAdditional) {
          if (addEq.attachment?.attachmentId === attachment.id) {
            addEq.VARSheetAttachmentUrl = undefined;
            addEq.attachment = undefined;
          }
        }
      }
      await appRef.set(
        {
          equipment: app.equipment,
          equipmentAdditional: app.equipmentAdditional,
          updatedAt: new Date(),
        },
        {merge: true}
      );
    }
  };

  const deleteFolders = async (folderNames: string[]) => {
    if (!folderNames.length) {
      return;
    }
    const ref = await Firebase.firestore.collection(collection).doc(entityId);
    await ref.set(
      {
        attachmentsFolders: Firebase.FieldValue.arrayRemove(...folderNames),
      },
      {merge: true}
    );
    await populateFolderNameStates();
  };

  const handleDeleteFileFolders = async (fileFolders: FileFolder[]) => {
    try {
      const attachmentsToDelete: AttachmentView[] = [];
      const foldersToDelete: string[] = [];
      for (const fileFolder of fileFolders) {
        if (!isErpAdmin && fileFolder.isSystemFolder) {
          continue;
        }
        attachmentsToDelete.push(...getFiles(fileFolder));
        if (!fileFolder.isFile && !fileFolder.isSystemFolder) {
          foldersToDelete.push(fileFolder.name);
        }
      }
      for (const attachment of attachmentsToDelete) {
        await deleteAttachment(attachment);
      }
      await deleteFolders(foldersToDelete);
      setSelectedFileFolders([]);
      showNotification('success', 'Successfully deleted.');
    } catch (err: any) {
      console.error(`failed to delete document. ${err.toString()}`);
      showNotification('error', 'Failed to delete.');
    }
  };

  const handleDeleteSelectedFileFolders = () => async () => {
    await handleDeleteFileFolders(selectedFileFolders);
  };

  const handleDeleteFileFolder = (fileFolder: FileFolder) => async () => {
    try {
      await handleDeleteFileFolders([fileFolder]);
    } catch (err: any) {
      console.error(`failed to delete document. ${err.toString()}`);
    }
  };

  const handleDownloadSelectedAttachments = async () => {
    try {
      if (!selectedFileFolders.length) {
        return;
      }
      setIsDownloading(true);
      const attachmentsToDownload: (AttachmentView & AttachmentMetadata)[] = [];
      for (const fileFolder of selectedFileFolders) {
        attachmentsToDownload.push(...getFiles(fileFolder));
      }
      if (!attachmentsToDownload.length) {
        showNotification('info', 'No Attachments to Download.');
        return;
      }
      if (attachmentsToDownload.length === 1) {
        await startDownload(attachmentsToDownload[0]);
        return;
      }
      const downloadInfos: DownloadInfo[] = [];
      for (const attachment of attachmentsToDownload) {
        const downloadInfo = getDownloadInfo(attachment);
        downloadInfos.push(downloadInfo);
      }

      const response = await createDownloadFilesRequest({
        downloadInfos: downloadInfos,
      });
      if (response.status !== 'ok') {
        return;
      }

      const downloadUrl = await Firebase.storage.ref(response.cloudPath).getDownloadURL();

      window.open(downloadUrl, '_blank', 'noopener');
    } catch (err: any) {
      console.error(`failed to delete document. ${err.toString()}`);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleRenameFolder = async (fileFolder: FileFolder, newFolderName: string) => {
    try {
      const oldFolderName = fileFolder.name;
      await moveFileFolders(newFolderName, [fileFolder]);
      await deleteFolders([oldFolderName]);
      showNotification('success', 'Folder name successfully updated.');
    } catch (err: any) {
      console.error(`failed to rename Folder. ${err.toString()}`);
      showNotification('error', 'Failed to rename Folder.');
    }
  };

  const handleSaveLabel = async (id: string, label: string) => {
    try {
      const snapshot = await Firebase.firestore
        .collection(collection)
        .doc(entityId)
        .collection(Collections.attachments)
        .doc(id)
        .get();
      if (snapshot.exists) {
        await snapshot.ref.set({label}, {merge: true});
        showNotification('success', 'File Label successfully updated.');
      }
    } catch (err: any) {
      console.error(`failed to update File Label. ${err.toString()}`);
      showNotification('error', 'Failed to update File Label.');
    }
  };

  const handleRename = async (fileFolder: FileFolder, newName: string) => {
    if (fileFolder.isFile) {
      await handleSaveLabel(fileFolder.id, newName);
      return;
    }
    if (fileFolder.isSystemFolder) {
      //do nothing. don't allow to rename system folders
      return;
    }
    await handleRenameFolder(fileFolder, newName);
  };

  const getDownloadInfo = (attachment: AttachmentView & AttachmentMetadata): DownloadInfo => {
    let fileName = attachment.name;
    if (collection === Collections.applications) {
      const ext = getFileExtensionFromUrl(attachment.downloadUrl);
      fileName = `${attachment.label}.${ext}`;
    }

    if (attachment.folderName) {
      fileName = `${attachment.folderName}/${fileName}`;
    }

    return {
      url: attachment.downloadUrl,
      fileName,
    };
  };

  const handleTypeClick = (fileFolder: FileFolder) => {
    if (fileFolder.isFile) {
      const files = getFiles(fileFolder);
      if (files.length) {
        startDownload(files[0]);
      }
      return;
    }
    setFilterFolder(fileFolder);
    setSelectedFileFolders([]);
  };

  const startDownload = (attachment: AttachmentView & AttachmentMetadata) => {
    const {url, fileName} = getDownloadInfo(attachment);
    if (!url) {
      showNotification('error', 'File not found on server.');
      return;
    }
    downloadUrl(url, fileName);
  };

  const handleSelectClick = (event: MouseEvent<unknown>, fileFolder: FileFolder) => {
    const isSelected = !!selectedFileFolders.find(x => x.id === fileFolder.id);
    let newSelected: FileFolder[] = [];

    if (isSelected) {
      newSelected = selectedFileFolders.filter(x => x.id !== fileFolder.id);
    } else {
      newSelected = newSelected.concat(selectedFileFolders, fileFolder);
    }

    setSelectedFileFolders(newSelected);
  };

  const populateFolderNameStates = useCallback(async () => {
    const existingFolderNames = await getFolderNames();
    const folders = [...defaultFolderNames, ...existingFolderNames].sort((a, b) => {
      const aUpper = a.toUpperCase();
      const bUpper = b.toUpperCase();
      if (aUpper > bUpper) {
        return 1;
      }
      if (bUpper > aUpper) {
        return -1;
      }
      return 0;
    });
    setFolderNames(folders);
    setFolderNamesToMove([rootFolder, ...folders]);
  }, [getFolderNames]);

  const handleCreateFolder = async (folderName: string | undefined) => {
    try {
      await addNewFolder(folderName);
      showNotification('success', 'Folder successfully created.');
    } catch (err: any) {
      console.error(`failed to create folder. ${err.toString()}`);
      showNotification('error', 'Failed to create Folder.');
    }
  };

  useEffect(() => {
    populateFolderNameStates().catch((err: any) => {
      console.error(`failed to load folders. ${err.toString()}`);
      showNotification('error', 'Failed to load folders.');
    });
  }, [populateFolderNameStates]);

  useEffect(() => {
    const fileFoldersToDisplay: FileFolder[] = [];
    folderNames.map((x, index) => {
      fileFoldersToDisplay.push({
        id: `${x}${index}`,
        isFile: false,
        isSystemFolder: defaultFolderNames.includes(x),
        name: x,
        label: x,
        sizeStr: '',
        contentType: '',
        createdAtStr: '',
        uploadedBy: '',
        folderName: null,
        fileIsAvailable: null,
        fileDownloadUrl: null,
      } as FileFolder);
    });

    attachments.data?.map(x => {
      fileFoldersToDisplay.push({
        id: x.id,
        isFile: true,
        isSystemFolder: false,
        name: x.name,
        label: x.label,
        sizeStr: prettyBytes(x.size),
        contentType: x.contentType,
        createdAtStr: format(x.createdAt.toDate(), 'MM/dd/yyyy h:mm a'),
        uploadedBy: x.author?.name ?? 'System Generated',
        folderName: x.folderName ?? null,
        fileIsAvailable: !!x.downloadUrl,
        fileDownloadUrl: !!x.downloadUrl ? x.downloadUrl : null,
      } as FileFolder);
    });

    setFilesFolders(fileFoldersToDisplay);
  }, [attachments.data, folderNames]);

  useEffect(() => {
    const fileFoldersToDisplay: FileFolder[] = filesFolders.filter(
      x => x.folderName === filterFolderName
    );
    const sortedData = fileFoldersToDisplay.slice().sort(getComparator(order, orderBy));

    setFilteredFilesFolders(sortedData);
  }, [filterFolderName, filesFolders, order, orderBy]);

  if (attachments.promised) return <Loading />;

  const numSelected = selectedFileFolders.length;
  const rowCount = filteredFilesFolders.length;
  const attachmentsRootText = `Attachments (${attachments.data?.length ?? 0})`;

  const showFileFolderDeleteConfirmation = (fileFolder: FileFolder) => {
    let message: string = 'Are you sure you want to delete this attachment?';
    if (!fileFolder.isFile) {
      if (fileFolder.isSystemFolder) {
        if (isErpAdmin) {
          message =
            'System folder will not be removed. Only files in the folder will be deleted. Are you sure you want to delete all attachments in the folder?';
        } else {
          message =
            'System folder and files in system folders will not be removed. Are you sure you want to delete selected items?';
        }
      } else {
        message = 'Are you sure you want to delete folder and all attachments in that folder?';
      }
    }
    showDeleteConfirmation(message, handleDeleteFileFolder(fileFolder));
  };

  const showSelectedFileFolderDeleteConfirmation = () => {
    if (selectedFileFolders.length === 0) {
      return;
    }
    if (selectedFileFolders.length === 1) {
      showFileFolderDeleteConfirmation(selectedFileFolders[0]);
      return;
    }

    const hasSystemFolder = !!selectedFileFolders.find(x => x.isSystemFolder);

    let message: string = 'Are you sure you want to delete selected items?';
    if (hasSystemFolder) {
      if (isErpAdmin) {
        message =
          'System folders will not be removed. Only files in the system folders will be deleted. Are you sure you want to delete selected items?';
      } else {
        message =
          'System folders and files in system folders will not be removed. Are you sure you want to delete selected items?';
      }
    }
    showDeleteConfirmation(message, handleDeleteSelectedFileFolders());
  };

  const showDeleteConfirmation = (message: string, action: () => Promise<void>) => {
    setDeleteConfirmationDialog({
      isOpen: true,
      confirmationAction: action,
      message,
    });
  };

  const getItemTooltipText = (fileFolder: FileFolder): string => {
    if (fileFolder.isFile) {
      if (!fileFolder.fileIsAvailable) {
        return 'File not found on server';
      }
      return 'Attachment';
    }
    if (fileFolder.isSystemFolder) {
      return 'System folder';
    }
    return 'User folder';
  };

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof FileFolder | undefined
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const isDesc = orderBy === property && order === 'desc';
    if (!orderBy) {
      setOrder('asc');
    } else {
      setOrder(isAsc ? 'desc' : 'asc');
    }
    if (!!orderBy && isDesc) {
      setOrderBy(undefined);
    } else {
      setOrderBy(property);
    }
  };

  const headCells: HeadCell[] = [
    {
      id: 'name',
      label: 'Type',
      sortable: true,
      className: classes.tableNameHeader,
    },
    {
      id: 'label',
      label: 'Name',
      sortable: true,
      className: classes.tableFileLabelHeader,
    },
    {
      id: 'sizeStr',
      label: 'Size',
      className: classes.tableSizeHeader,
    },
    {
      id: 'contentType',
      label: 'File Type',
    },
    {
      id: 'createdAtStr',
      label: 'Date Created',
      sortable: true,
      className: classes.tableDateHeader,
    },
    {
      id: 'uploadedBy',
      label: 'Uploaded By',
      sortable: true,
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              {!filterFolder && attachmentsRootText}
              {filterFolder && (
                <>
                  <a
                    href="#"
                    onClick={evt => {
                      setFilterFolder(null);
                      setSelectedFileFolders([]);
                      evt.preventDefault();
                    }}
                  >
                    {attachmentsRootText}
                  </a>
                  {` / ${filterFolderName}`}
                </>
              )}
            </Typography>
            {!readOnly && (
              <>
                {!filterFolder && (
                  <Button
                    id="createFolderButton"
                    name="createFolder"
                    className={classes.newFolderButton}
                    onClick={() => {
                      setCreateFolderAction(
                        () => (form?: CreateFolderForm) => handleCreateFolder(form?.folderName)
                      );
                    }}
                    variant="outlined"
                    color="secondary"
                    startIcon={<CreateNewFolderIcon />}
                  >
                    New
                  </Button>
                )}
                <Button
                  id="moveSelectedButton"
                  name="moveSelected"
                  className={classes.moveButton}
                  onClick={() => {
                    setMoveDialogOpen({isOpen: true});
                  }}
                  variant="outlined"
                  color="secondary"
                  startIcon={<DriveFileMoveIcon />}
                  disabled={selectedFileFolders.length === 0 || !canMove}
                >
                  Move
                </Button>
                {allowDelete && (
                  <Button
                    id="deleteSelectedButton"
                    name="deleteSelected"
                    className={classes.deleteButton}
                    onClick={() => showSelectedFileFolderDeleteConfirmation()}
                    variant="outlined"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    disabled={selectedFileFolders.length === 0 || !canDelete}
                  >
                    Delete
                  </Button>
                )}
                <LoadingButton
                  id="downloadSelectedButton"
                  name="downloadSelected"
                  className={classes.downloadButton}
                  onClick={handleDownloadSelectedAttachments}
                  variant="outlined"
                  color="secondary"
                  startIcon={<DownloadIcon />}
                  disabled={selectedFileFolders.length === 0}
                  loading={isDownloading}
                >
                  Download
                </LoadingButton>
                <div>
                  <Button
                    id="uploadButton"
                    name="uploadDocument"
                    className={classes.uploadButton}
                    onClick={handleAttachClick}
                    variant="outlined"
                    color="secondary"
                    startIcon={<PublishIcon />}
                  >
                    Upload
                  </Button>
                </div>
              </>
            )}
          </Toolbar>
          <Table className={classes.table} aria-label="attachments table">
            <AttachmentsTableHead
              handleRequestSort={handleRequestSort}
              handleSelectAllClick={handleSelectAllClick}
              numSelected={numSelected}
              orderBy={orderBy}
              order={order}
              rowCount={rowCount}
              headCells={headCells}
            />
            <TableBody>
              {filteredFilesFolders.length > 0 &&
                filteredFilesFolders.map(e => (
                  <TableRow key={e.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={!!selectedFileFolders.find(x => x.id === e.id)}
                        onClick={event => handleSelectClick(event, e)}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <a
                        href={e.fileIsAvailable ? e.fileDownloadUrl! : '#'}
                        target={e.fileIsAvailable ? '_blank' : undefined}
                        onClick={evt => {
                          if (e.fileIsAvailable) {
                            return;
                          }
                          handleTypeClick(e);
                          evt.preventDefault();
                        }}
                      >
                        <Tooltip
                          title={getItemTooltipText(e)}
                          TransitionProps={{
                            timeout: {
                              appear: 500,
                              enter: 300,
                              exit: 0,
                            },
                          }}
                        >
                          <span>
                            {e.isFile && e.fileIsAvailable && (
                              <FilePresentIcon className={classes.itemIcon} />
                            )}
                            {e.isFile && !e.fileIsAvailable && (
                              <FileDownloadOffIcon className={classes.itemIcon} />
                            )}
                            {!e.isFile && e.isSystemFolder && (
                              <FolderSpecialIcon className={classes.itemIcon} />
                            )}
                            {!e.isFile && !e.isSystemFolder && (
                              <FolderIcon className={classes.itemIcon} />
                            )}
                          </span>
                        </Tooltip>
                        <BoxEllipsis
                          sx={{
                            display: 'inline-block',
                            position: 'relative',
                            top: '3px',
                            paddingLeft: '10px',
                            maxWidth: '200px',
                          }}
                        >
                          {e.name}
                        </BoxEllipsis>
                      </a>
                    </TableCell>
                    <TableCell className={classes.tableFileLabelRow}>
                      {!e.isSystemFolder && (
                        <InlineTypography
                          tooltip={
                            e.isFile ? 'Click to edit File Label' : 'Click to edit Folder name'
                          }
                          emptyValue={'no label'}
                          value={e.label}
                          setText={label => handleRename(e, label)}
                          tooltipPlacement={TooltipPlacement.BottomEnd}
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="caption">{e.sizeStr}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="caption">{e.contentType}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="caption">{e.createdAtStr}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="caption">{e.uploadedBy}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {e.fileIsAvailable && (
                        <IconButton
                          size="large"
                          sx={{mr: 1}}
                          onClick={evt => {
                            handleTypeClick(e);
                            evt.preventDefault();
                          }}
                        >
                          <DownloadIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {allowDelete &&
                        (isErpAdmin || (!filterFolder?.isSystemFolder && !e.isSystemFolder)) && (
                          <IconButton
                            onClick={() => showFileFolderDeleteConfirmation(e)}
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                    </TableCell>
                  </TableRow>
                ))}
              {!filteredFilesFolders.length && (
                <TableRow>
                  <TableCell colSpan={6} sx={{textAlign: 'center'}}>
                    No Attachments
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      {createDialogOpen.isOpen && (
        <InsertAttachmentsDialog
          initialFolderName={filterFolderName}
          documentTypeOptions={documentTypeOptions}
          folderNamesOptions={folderNames}
          onSubmit={handleSelectedFiles}
          onClose={() => setCreateDialogOpen({isOpen: false})}
        />
      )}
      {deleteConfirmationDialog.isOpen && (
        <ConfirmationDialog
          title="Confirmation"
          message={deleteConfirmationDialog.message ?? ''}
          onClose={() =>
            setDeleteConfirmationDialog({
              isOpen: false,
            })
          }
          onConfirm={deleteConfirmationDialog.confirmationAction ?? null}
        />
      )}
      {moveDialogOpen.isOpen && (
        <MoveAttachmentsDialog
          folderNamesOptions={folderNamesToMove}
          onSubmit={handleSelectedFileFoldersMove}
          onClose={() => setMoveDialogOpen({isOpen: false})}
        />
      )}
      <ConfirmationDialog
        title="Create New Folder"
        message=""
        fields={[new ConfirmationDialogField('folderName', 'New Folder Name', '', null)]}
        onClose={() => setCreateFolderAction(null)}
        onConfirm={createFolderAction}
      />
    </Grid>
  );
};

export type {AttachmentsRef};
export {Attachments};
