import {
  Collections,
  Firebase,
  Group,
  GroupView,
  selectGroupView,
  UniversalSnapshot,
} from '@ozark/common';
import {Domain} from '@ozark/functions/src/constants';
import {useEffect, useState} from 'react';
import {AsyncState} from '../models';

// hacky temporary solution
// https://enigmata.atlassian.net/browse/OZ-255
const getDefaultGroup = (domain: Domain, hostname: string) =>
  ({
    id: 'W4JkQQ6GhUYIYwUInEg8',
    address1: '4100 Newport Place Drive',
    address2: 'Suite 500',
    appDomain: 'localhost:3001',
    city: 'Newport Beach',
    createdAt: '1594505538000',
    hexColorPrimary: '#3b69ec',
    hexColorTextPrimary: '#ffffff',
    logoUrl:
      'https://firebasestorage.googleapis.com/v0/b/ozark-production.appspot.com/o/groups%2FLuqra%20Logo%20New.png?alt=media&token=eb41766f-2c04-4135-8495-86a51714e69c',
    logoThumbnailUrl:
      'https://storage.googleapis.com/ozark-production.appspot.com/groups%2FLuqra%20Logo%20New_thumbnail.png',
    name: 'Luqra',
    phone: '+18007705520',
    portalDomain: 'localhost:3002',
    state: 'CA',
    termsUrl: 'www.luqra.com/tandc',
    zipCode: '92660',
    ref: {},
    [domain]: hostname,
  } as unknown);

export const useGroupFromDomain = (domain: Domain) => {
  const [group, setGroup] = useState<AsyncState<GroupView>>({promised: true});
  useEffect(() => {
    let hostname = window.location.hostname;
    if (window.location.port) {
      hostname += `:${window.location.port}`;
    }
    const unsubscribe = Firebase.firestore
      .collection(Collections.groups)
      .where(domain, '==', hostname)
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            setGroup({
              promised: false,
              data: getDefaultGroup(domain, window.location.hostname) as GroupView,
            });
            return;
          }
          const group = selectGroupView(snapshot.docs[0] as UniversalSnapshot<Group>);
          setGroup({promised: false, data: group});
        },
        err => {
          console.error(err);
          setGroup({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, []);
  return {group};
};
